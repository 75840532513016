// const CDN_BASE_URL = 'https://sert-consumer.s3.amazonaws.com/images/logos';

// Sort order based on groups, and then race
const classificationSort = {
    "Women Owned": 1,
    "LGBTQ": 2,
    "Minority": 3,
    "Veteran Owned": 4,
    "Disability": 5,
    "Asian": 6,
    "Black Owned": 7,
    "Hispanic": 8,
    "Native American": 9,
    "Small Business": 10, 
};

const partnerSettings = [
    {
        partner: "cj",
        publisherIdReq: true,
        publisherIdLabel: "CJ Publisher Company ID (CID)",
        publisherIdIconText: "Please check your CJ account to find your CJ Publisher Company ID.",
        businessLabel: "Business",
        examplesUrl: "https://sertify.io/partner/cj/examples",
    }, 
    {
        partner: "raptive",
        publisherIdReq: true,
        publisherIdLabel: "Raptive Publisher Company ID",
        eligibleDocumentText: "Eligible document that shows you are the owner of the business",
        publisherIdIconText: "Please check your Raptive account to find your Raptive Publisher Company ID.",
        businessLabel: "Business",
        examplesUrl: "https://sertify.io/sertification-examples",
    }, 
    {
        partner: "shareasale",
        publisherIdReq: false,
        publisherIdLabel: null,
        businessLabel: "Business",
        examplesUrl: "https://sertify.io/partner/shareasale/examples",
    }, 
    {
        partner: "openx",
        publisherIdReq: true,
        publisherIdLabel: "OpenX Publisher Company ID",
        eligibleDocumentText: "Eligible document that shows you are the owner of the business",
        publisherIdIconText: "Please check your OpenX account to find your OpenX Publisher Company ID.",
        businessLabel: "Business",
        examplesUrl: "https://sertify.io/sertification-examples",
    }, 
    {
        partner: "mediavine",
        publisherIdReq: false,
        publisherIdLabel: "Mediavine Site ID",
        publisherIdIconText: "Please check your Medavine account to find your Mediavine Site ID.",
        businessLabel: "Business",
        examplesUrl: "https://sertify.io/sertification-examples",
    }, 
]

const premiumPartnerLookup = [
    {partner: "cj", advertiser: "expedia"},
    {partner: "cj", advertiser: "cvs"},
    {partner: "raptive", advertiser: null},
    {partner: "openx", advertiser: null},
    {partner: "shareasale", advertiser: null},
    {partner: "mediavine", advertiser: null},
];

/**
 * Helper function to determine which sticker image to show IF business applied for more than one
 * @param {array} classifications 
 */
export function getPartnerSettings(partner) {
    const matchedPartner = partnerSettings.find((element) => element.partner === partner);
    if (matchedPartner) {
        return matchedPartner;
    } 
    return null;
}

/**
 * Helper function to determine which sticker image to show IF business applied for more than one
 * @param {array} classifications 
 */
export function getSortedDecalForDisplay(classifications) {
    return classifications.sort(function(classification1, classification2) {
        return classificationSort[classification1] - classificationSort[classification2];
    })
}

export function validatePremiumPartner(partner, advertiserParam) {
    // check to see if partner/advertiser is a known combo
    if (!partner) {
        return false;
    } else {
        const validPremiumPartner = premiumPartnerLookup.find(element => 
            element.partner === partner 
            && element.advertiser === advertiserParam);
        if (validPremiumPartner) {
            return true;
        } else {
            return false;
        }
    }
}

export function capitalizeFirstLetter(str) {
    return str.replace(/^./, str[0].toUpperCase());
}