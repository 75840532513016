import React, { useState, useEffect } from "react";
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import { isMobile } from 'react-device-detect';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./InfoIcon.css";

export default function InfoIcon({
    keyName,
    message,
    ...props
}) {
    const [placement, setPlacement] = useState("right");    
    const [showPopover, setShowPopover] = useState(false); 
    const childNode = React.useRef();


    const popover = (
        <Popover 
            onMouseOver={() => setShowPopover(true)}
            onMouseOut={() => setShowPopover(false)}
            className="info-icon-popover" 
            id={"popover-"+keyName}>
          <Popover.Content>
            {message}
          </Popover.Content>
        </Popover>
    );

    useEffect(() => {
        if (isMobile) {
            //mobile is narrower, hence all info icon display at bottom
            setPlacement("bottom")
        }
    }, []);

    return (
        <span 
            className="info-icon"
            onMouseOver={() => setShowPopover(true)}
            onMouseOut={() => setShowPopover(false)}
            ref={childNode}>

            <FontAwesomeIcon className="info-icon" color="grey" icon={['fas', 'info-circle']}></FontAwesomeIcon>
            <Overlay
                show={showPopover}
                onHide={() => setShowPopover(false)}
                placement={placement}
                target={() => childNode.current}>
                {popover}
            </Overlay>
        </span>
    );
}
