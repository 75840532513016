const CDN_BASE_URL = 'https://sert-consumer.s3.amazonaws.com/images/logos';

const headerLogoDisplayMap = {
    "cj": {
        image:`${CDN_BASE_URL}/sertify_cj_v2.png`, 
    },
    "expedia": {
        image:`${CDN_BASE_URL}/sertify_cj_expedia.png`, 
    },
    "shareasale": {
        image:`${CDN_BASE_URL}/sertify_shareasale_clear_bg.png`, 
    },
    "cvs": {
        image:`${CDN_BASE_URL}/sertify_cj_cvs.png`, 
    },
    "raptive": {
      image:`${CDN_BASE_URL}/sertify_raptive.png`, 
    },
    "openx": {
        image:`${CDN_BASE_URL}/sertify_openx.png`, 
    },
    "mediavine": {
      image:`${CDN_BASE_URL}/sertify_mediavine.png`, 
    },
    "sertify": {
        image:`${CDN_BASE_URL}/Sertify_logoart_black_whitedoor_RGB.png`, 
    },
};

export default headerLogoDisplayMap;
