import React from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import AdditionalInfo from "./containers/AdditionalInfo";
import Account from "./containers/Account";
import Classifications from "./containers/Classifications";
import Identity from "./containers/Identity";
import Login from "./containers/Login";
import Logout from "./containers/Logout";
import NotFound from "./containers/NotFound";
import Ownership from "./containers/Ownership";
import Payment from "./containers/Payment";
import Websites from "./containers/Websites";
import ResetPassword from "./containers/ResetPassword";
import Success from "./containers/Success";
import Steps from "./components/Steps";


export default function Routes() {
    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    let query = useQuery();

    function redirectTo(query) {
        if (query) {
            return "/?" + query + "&partner=:partner";
        } else {
            return "/?partner=:partner";
        }
    }

    return (
        <Switch>
            <Redirect from="/partner/:partner" to={redirectTo(query)} />
            <Route exact path="/logout">
                <Logout />
            </Route>
            <Route exact path="/">
                <Steps activeTab="create" />
                <Account />
            </Route>
            
            { /*
            
            <Route exact path="/account">
                <Steps activeTab="create" />
                <Account />
            </Route>
            */}
            <UnauthenticatedRoute exact path="/login">
                <Login />
            </UnauthenticatedRoute>
            <UnauthenticatedRoute exact path="/login/reset">
                <ResetPassword />
            </UnauthenticatedRoute>
            <AuthenticatedRoute exact path="/classifications" >
                <Steps activeTab="account"/>
                <Classifications />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/websites" >
                <Steps activeTab="account"/>
                <Websites />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/ownership" >
                <Steps activeTab="ownership"/>
                <Ownership />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/identity" >
                <Steps activeTab="identity"/>
                <Identity />
            </AuthenticatedRoute>
            { 
            /* 10/27/2020: comment out giveback page until we are ready to put back feature
            <AuthenticatedRoute exact path="/giveback" >
                <Steps activeTab="payment"/>
                <Donations />
            </AuthenticatedRoute>
            */ }
            <AuthenticatedRoute exact path="/payment" >
                <Steps activeTab="payment"/>
                <Payment />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/additionalInfo">
                <AdditionalInfo />
            </AuthenticatedRoute>
            <Route exact path="/success">
                <Success />
            </Route>
            {/* Finally, catch all unmatched routes */}
            <Route>
                <NotFound />
            </Route>
        </Switch>
    );
}