import React, {useEffect, useState} from "react";
import { useAppContext } from "../libs/contextLib";
import headerLogoDisplayMap from "../constants/headerLogoDisplayMap";
import "./Header.css";

export default function Header() {

  const { application } = useAppContext();
  const [partner, setPartner] = useState(headerLogoDisplayMap[null]);
  const [advertiser, setAdvertiser] = useState(null);
  const [backgroundCssClass, setBackgroundCssClass] = useState(null);
  const [logoContainerCssClass, setLogoContainerCssClass] = useState(null);
  const [logo, setLogo] = useState(headerLogoDisplayMap["sertify"]);
  useEffect(() => {

    async function onLoad() {
      if (application && application.partnerCode) {        
        // Inside logged in pages, check application data to see if partnerCode is defined
        if (headerLogoDisplayMap[application.partnerCode]) {
          setPartner(application.partnerCode);
          setLogo(headerLogoDisplayMap[application.partnerCode]);
        } else {
          // unknown partner. default to sertify
          setPartner(null);
        }

        // check to see application has advertiser code, and if advertiser is legit
        if (headerLogoDisplayMap[application.advertiser]) {
          setAdvertiser(application.advertiser); 
          setLogo(headerLogoDisplayMap[application.advertiser]);
          return;
        } 
      } else {
        // No application yet, could be from Account.js (sign up page)
        // check if URL parameter may have valid partner code
        const search = window.location.search;
        const params = new URLSearchParams(search);
        if (params.get('partner') !== undefined && params.get('partner') !== null) {
          if (headerLogoDisplayMap[params.get('partner')]) {
            setPartner(params.get('partner'));
            setLogo(headerLogoDisplayMap[params.get('partner')]);

            // check to see if partner has advertiser
            if (params.get('advertiser') !== undefined && params.get('advertiser') !== null) {
              if (headerLogoDisplayMap[params.get('advertiser')]) {
                setAdvertiser(params.get('advertiser')); 
                setLogo(headerLogoDisplayMap[params.get('advertiser')]);
              } 
            } 
          } 
        } else {
          setPartner(null);
          setLogo(headerLogoDisplayMap["sertify"]);
        }
      }
    }
    onLoad();
  }, [application]); 

  useEffect(() => {

    if (partner === 'shareasale' || partner === 'raptive' || partner === 'openx' || partner === 'mediavine') {
      setBackgroundCssClass("white-bg")
      setLogoContainerCssClass("large-logo-container") 
    } else if (partner === 'cj') {
      setLogoContainerCssClass("large-logo-container") 
      if (advertiser === 'expedia' || advertiser === 'cvs') {
        // use white background due to expedia logo is blue
        setBackgroundCssClass("white-bg")
      } else {
        setBackgroundCssClass("bg")
      }
    } else {
      // default sertify
      setLogoContainerCssClass("logo-container") 
      setBackgroundCssClass("bg")
    }
  }, [partner, advertiser]); 

  return (
      <header>
        <div className={backgroundCssClass}>
          <div className="header-logo-container">  
            <div className={logoContainerCssClass}>
                <img src={logo.image} alt="Sertify.io"/>
            </div>
          </div>
        </div>
      </header>

  );
}