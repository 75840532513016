import React from "react";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import "./LoaderButton.css";

export default function LoaderButton({
    isLoading,
    className = "",
    disabled = false,
    ...props
}) {
    return (
        <Button
            className={`LoaderButton ${className}`}
            disabled={disabled || isLoading}
            {...props}
            >
            {isLoading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true">&nbsp;</Spinner> }
            &nbsp;{props.children}
        </Button>
    );
}