import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAppContext } from "../libs/contextLib";
import { Auth } from "aws-amplify";

export default function Logout() {
    const { userHasAuthenticated, setApplication } = useAppContext(); 
    const history = useHistory();

    useEffect(() => {
        /**
         * Set state and signout from Auth
         */
        async function onLoad() {
            await Auth.signOut();
            setApplication(null);
            userHasAuthenticated(false);
            history.push("/");
        }

        onLoad();
    });  

    return (
      <div></div>
    );
}