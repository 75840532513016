import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { useAppContext } from "../libs/contextLib";
import { getQueryString } from "../utils/Helper";


/**
 * Custom route component for non authenticated users
 * https://serverless-stack.com/chapters/create-a-route-that-redirects.html
 * 
 * @param {*} param0 
 */
export default function UnauthenticatedRoute({ children, ...rest }) {
    const { isAuthenticated } = useAppContext();
    const { application } = useAppContext();

    /*
        This component is mainly used by Routes/Login.  
        If user is not authenticated or no application is found, then user goes to the Login route (or whichever route is wrapped by UnauthenticatedRoute)
        Otherwise: user is dropped into:
            - Success page: if application is submitted and paid
            - Additional info page (/additionalInfo) if application is "additionalInfo" stage 
            - Beginning of application steps (/classifications) if application is still in draft mode submitted 
    */
    useEffect(() => {
        //console.log("*****IN UnauthenticatedRoute: application: " + JSON.stringify(application));
        //TODO: check 
    });  

    const redirect = getQueryString("redirect");
    return (
        <Route {...rest}>
        {
            //If not authenticated or no application found, render children 
            (!isAuthenticated || !application) && (
                children
            )
        }
        {
            //If application is submitted and paid, or have been approved/denied, go to Success page
            application && 
                (
                    (application.applicationStatus === 'submitted') 
                    || 
                    (application.applicationStatus === 'denied' || application.applicationStatus === 'approved')
                )
                && (
                <Redirect to={"/success"} />
            )
        }
        {
            //If authenticated and application status is in "additionalInfo" mode, start at additionalInfo page
            application && application.applicationStatus === 'requestDocs' && (
                <Redirect to={redirect === "" || redirect === null ? "/additionalInfo" : redirect} />
            )
        }
        {
            //If authenticated and application status is in "draft" mode, start at classification page
            application && application.applicationStatus === 'draft' && (
                <Redirect to={redirect === "" || redirect === null ? "/classifications" : redirect} />
            )
        }
        </Route>
    );
}