import React, {useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import Modal from 'react-bootstrap/Modal';
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { API } from "aws-amplify";
import { useAppContext } from "../libs/contextLib";
import { onError } from "../libs/errorLib";
import { s3Upload, s3Delete } from "../libs/awsLib";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLoader } from "../context/LoaderContext";
import "./FileUpload.css";

export default function FileUpload(props) {
    const { application, files, setFiles, setIsValidated } = useAppContext();
    const [isProcessing, setIsProcessing] = useState(false);

    const [showError, setShowError] = useState(false);
    const handleClose = () => setShowError(false);
    const [, setLoading] = useLoader();
    
    useEffect(() => {
        setLoading(isProcessing);
    }, [isProcessing, setLoading]);

    //TODO!!!
    // 1. Limit file types and size?
    // 2. Only allow to go back and next IF at least one document is uploaded?
    const removeFileChange = async (event, file) => {
        event.preventDefault();
        setIsProcessing(true);
        try {
            //1. Remove from persistance 
            await deleteFile(file.documentId);

            //2. Remove from bucket
            await s3Delete(file.fileName);

            //3. Remove from page files list
            const newFilesList = files.filter(function( obj ) {
                return obj.documentId !== file.documentId;
            });
            setFiles(newFilesList);
            
            if (newFilesList < 1) {
                setIsValidated(false);
            } else {
                setIsValidated(true);
            }
        } catch (e) {
            onError(e);
        }
        setIsProcessing(false);
    }

    const addFileChange = async (event) => {
        event.preventDefault();
        setIsProcessing(true);

        try {
            var file = event.target.files[0];

            // TODO: support greater than 5MB
            const fileSize = file.size / 1024 / 1024; // in MiB
            if (fileSize > 5) {
                setShowError(true);
                setIsProcessing(false);
                return;
            }
            console.log("****fileSize: " + fileSize)
            //1. Upload to bucket
            const s3FileName = file ? await s3Upload(file) : null;

            if (!s3FileName) 
                console.log("****missing filename")
            //2. Persist file path 
            const savedFile = await saveFile(s3FileName);
            
            //3. Add to page files list
            file.documentId = savedFile.documentId;
            file.fileName = s3FileName;
            files.push(file);
            setFiles(files);

            if (files < 1) {
                setIsValidated(false);
            } else {
                setIsValidated(true);
            }
        } catch (e) {
            console.log("****hello")
            onError(e);
        }
        setIsProcessing(false);
    }
    
    function saveFile(fileName) {
        return API.post("applications", "/applications/" + application.applicationId + "/documents", {
            body: {
                documentType: props.sectionName,
                S3FileName: fileName,
            }
        });
    }

    async function deleteFile(documentId) {
        try {
            setIsProcessing(true);
            await API.del("applications", "/applications/" + application.applicationId + "/documents/" + documentId, {});
        } catch (e) {
            onError(e);
        } finally {
            setIsProcessing(false);
        }
    }

    return (
        <div className="FileUpload">
            <div className="instructions">
                <p>Please upload <strong>one</strong> document or picture. It will be kept completely <a href="https://sertify.io/privacy" target="_blank" rel="noopener noreferrer">private</a> and secure.</p>
            </div>
            <Form>
                {
                    /* UI hack for now: 
                        - If no files, then create a centered version of image upload
                        - Otherwise, do a 2 column display with left side as image upload, and right side as files list
                    */
                    (!files || files.length < 1) && (
                        <Form.Group as={Col} style={{textAlign:"center", border:"solid", borderColor:"#adadad", borderWidth:"thin", borderRadius:"5px"}} controlId="imgupload">
                            <div className="uploadImageContainer">
                                <input type="file" 
                                    id="imgupload" 
                                    onClick={(event) => {
                                        //reset the value so that onChange will fire again if user uploads the same file
                                        event.target.value = '';
                                    }}
                                    onChange={addFileChange} 
                                    style={{display:"none"}}/>
                                <label htmlFor="imgupload">
                                    <div className="uploadImage">
                                    
                                    </div>
                                    <span className="uploadImageText-mobile">Add Photo</span>
                                    <span className="uploadImageText-desktop">Click to add document</span>
                                    { /*
                                    <img src="https://sert-consumer.s3.amazonaws.com/images/file_upload.png"></img>
                                    */}
                                </label>
                            </div>
                        </Form.Group>
                    )
                }
                {
                    (files && files.length > 0) && (
                    <Form.Row>
                        <Form.Group as={Col} xs={5} lg={4} controlId="imgupload">
                            <div className="filesSection">
                                <div className="uploadImageContainer">
                                    <input type="file" 
                                        id="imgupload" 
                                        onClick={(event) => {
                                            //reset the value so that onChange will fire again if user uploads the same file
                                            event.target.value = '';
                                        }}
                                        onChange={addFileChange} 
                                        style={{display:"none"}}/>
                                    <label htmlFor="imgupload">
                                        <div className="uploadImage">
                                        
                                        </div>
                                        <span className="uploadImageText-mobile">Add Photo</span>
                                        <span className="uploadImageText-desktop">Click to add document</span>
                                        { /*
                                        <img src="https://sert-consumer.s3.amazonaws.com/images/file_upload.png"></img>
                                        */}
                                    </label>
                                </div>
                            </div>
                        </Form.Group>
                        <Form.Group as={Col} xs={7} lg={8} style={{paddingLeft: "20px"}} controlId="filesList">
                            <div className="">
                                {files && files.length > 0 && (
                                    <div>
                                        <strong>File uploaded:</strong>
                                        { files.map((file, index) => {
                                            return (
                                                <div key={file.name+"-"+index} className="Row">
                                                    <div className="Filename">
                                                        <span className="Filename">{file.fileName}</span>
                                                    </div>
                                                    <div>
                                                        <Button onClick={(e) => removeFileChange(e, file)}>
                                                            <FontAwesomeIcon icon="times-circle"></FontAwesomeIcon>
                                                        </Button>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                            </div>
                        </Form.Group>
                    </Form.Row>
                    )
                }
            </Form>
            <Modal show={showError} centered onHide={handleClose}>
                <Modal.Body>Sorry, please upload an image file less than 5 MB.</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}