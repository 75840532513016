import React, {useEffect, useState, useRef} from "react";
import { useHistory } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { AppContext, useAppContext } from "../libs/contextLib";
import { useFormFields } from "../libs/hooksLib";
import { API, Auth } from "aws-amplify";
import { useLoader } from "../context/LoaderContext";
import USStates from "../components/USStates"
import InfoIcon from '../components/InfoIcon';
import headerLogoDisplayMap from "../constants/headerLogoDisplayMap";
import { getPartnerSettings, validatePremiumPartner } from "../libs/utilsLib"
import "./Account.css";

const initCustomErrors = {
    email: '',
    password: '',
    confirmPassword: '',
};

export default function Account() {
    const [isProcessing, setIsProcessing] = useState(false);
    const [, setLoading] = useLoader();
    const [validated, setValidated] = useState(false);
    const { userHasAuthenticated } = useAppContext();
    const { setApplication } = useAppContext();
    const history = useHistory();
    const [fields, handleFieldChange] = useFormFields({
        businessName: "",
        dbaName: "",
        address1: "",
        city: "",
        state: "",
        zip: "",
        website: "",
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        password: "",
        confirmPassword: "",
        cjPublisherId: "",
    });
    const passwordRef = useRef(null);
    const confirmPasswordRef = useRef(null);
    const emailRef = useRef(null);
    const [customErrors, setCustomErrors] = useState(initCustomErrors);
    const [partnerCode, setPartnerCode] = useState(null);
    const [examplesUrl, setExamplesUrl] = useState("https://sertify.io/sertification-examples");
    const [utmSource, setUtmSource] = useState(null);
    const [utmMedium, setUtmMedium] = useState(null);
    const [utmCampaign, setUtmCampaign] = useState(null);
    const [partnerSettings, setPartnerSettings] = useState(null);
    const [advertiser, setAdvertiser] = useState(null);
    const [urlParams, setUrlParams] = useState(null);
        
    /**
    * Parse URL for referral information
    */
    useEffect(() => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        setUrlParams(params);

        let partner = null;
        if (params.get('partner') !== undefined && params.get('partner') !== null) {
            // Check if we know this partner code
            partner = params.get('partner').toLowerCase();
            if (headerLogoDisplayMap[partner]) {
                setPartnerCode(params.get('partner').toLowerCase());
                
                // Retrieve partner settings
                const partnerSettings = getPartnerSettings(partner);
                if (partnerSettings) {
                    setPartnerSettings(partnerSettings);
                    setExamplesUrl(partnerSettings.examplesUrl);
                }
            }
        }
        if (params.get('utm_source') !== undefined && params.get('utm_source') !== null) {
            setUtmSource(params.get('utm_source').toLowerCase());
        }
        if (params.get('utm_medium') !== undefined && params.get('utm_medium') !== null) {
            setUtmMedium(params.get('utm_medium').toLowerCase());
        }
        if (params.get('utm_campaign') !== undefined && params.get('utm_campaign') !== null) {
            setUtmCampaign(params.get('utm_campaign').toLowerCase());
        }
        if (params.get('advertiser') !== undefined && params.get('advertiser') !== null) {
            // validate advertiser to see if it's a known advertiser
            const advertiserParam = params.get('advertiser').toLowerCase();
            const isValidAdvertiser = validatePremiumPartner(partner, advertiserParam);
            if (isValidAdvertiser) {
                setAdvertiser(params.get('advertiser').toLowerCase());
            }
        }
    }, []);

    useEffect(() => {
        setLoading(isProcessing);
    }, [isProcessing, setLoading]);

    function validateForm() {
        emailRef.current.setCustomValidity('');
        confirmPasswordRef.current.setCustomValidity('');
        passwordRef.current.setCustomValidity('');

        //TODO: Need to add custom validation, such as making sure passwords match
        const passwordRegEx = new RegExp('^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,20}$');
        if (!passwordRegEx.test(fields.password)) {
            passwordRef.current.setCustomValidity('Please create a strong password');
            setCustomErrors({
                ...customErrors,
                password: 'Please enter a strong password',
            });
        } else if (fields.password !== fields.confirmPassword) {
            confirmPasswordRef.current.setCustomValidity('Passwords do not match.');
            setCustomErrors({
                ...customErrors,
                confirmPassword: 'Passwords do not match',
            });   
        }
    }

    const handleSubmit = async (event) => {
        
        event.preventDefault();
        event.stopPropagation();
        setValidated(false);

        setCustomErrors(initCustomErrors);

        validateForm();

        const form = event.currentTarget;
        const isValid = form.checkValidity();

        setValidated(true);

        if (!isValid) {
            return;
        }

        setIsProcessing(true);

        try {
            await Auth.signUp({
                username: fields.email,
                password: fields.password,
                attributes: { 
                    family_name: fields.lastName,
                    given_name: fields.firstName,
                    email: fields.email
                }
            });

            await Auth.signIn(fields.email, fields.password);

            const application = await saveApplication();
            setApplication(application);
            userHasAuthenticated(true);
            setValidated(true);
            history.push("/classifications");           
        } catch (e) {
            console.error(e);
            if (e.code === 'UsernameExistsException') {
                emailRef.current.setCustomValidity(e.message);
                setCustomErrors({
                    ...customErrors,
                    email: e.message,
                });
            }
        } finally {
            setIsProcessing(false);
        }
    };

    function saveApplication() {
        return API.post("applications", "/applications", {
            body: { 
                businessName: fields.businessName,
                email: fields.email,
                dbaName: fields.dbaName,
                address1: fields.address1,
                city: fields.city,
                state: fields.state,
                zip: fields.zip,
                // website: fields.website,
                firstName: fields.firstName,
                lastName: fields.lastName,
                phone: fields.phone,
                partnerCode: partnerCode,
                utmSource: utmSource,
                utmMedium: utmMedium,
                utmCampaign: utmCampaign,
                cjPublisherId: fields.cjPublisherId, // note: column name hasn't changed, hence still calls "cjPublisherId"
                advertiser: advertiser,
                urlParams: urlParams,
            },
        });
    }

    function getEligibleDocumentText(partnerSettings) {
        if (partnerSettings && partnerSettings.eligibleDocumentText) {
            return partnerSettings.eligibleDocumentText;
        } 
        return "Eligible document that shows name of business and ownership";
    }

    function getInfoIconText() {
        if (partnerSettings && (partnerSettings.partner === 'raptive' || partnerSettings.partner === 'openx')) {
            return (
                <div>
                    <p>Eligible document(s) include <strong>tax</strong>, <strong>financial</strong>, <strong>business licenses</strong>, and many more.</p>
                    <p>For a complete list of acceptable documents, please visit <a href={examplesUrl} target="_blank" id="info" rel="noopener noreferrer" aria-label=" What you need  "><strong>here</strong></a>.</p>
                    <p>We respect your privacy and will not use your information for any other purpose.</p>
                    <p>We only need to see the portion of the document that shows your name with the name of the business to prove ownership.</p>
                </div>
            );
        } 
        return (
            <div>
                <p>Eligible document(s) include <strong>tax</strong>, <strong>financial</strong>, <strong>business licenses</strong>, and many more.</p>
                <p>For a complete list of acceptable documents, please visit <a href={examplesUrl} target="_blank" id="info" rel="noopener noreferrer" aria-label=" What you need  "><strong>here</strong></a>.</p>
                <p>We respect your privacy and will not use your information for any other purpose.</p>
                <p>We only need to see the portion of the document that shows both your name and the information that supports the Sertify category you've selected.</p>
            </div>
        );
    }

    function getIdentityInfoIconText() {
        return (
            <div>
                <p>Eligible document(s) include <strong>relevant social media</strong>, <strong>business</strong> and <strong>driver license</strong>, and many more.</p>
                <p>For a complete list of acceptable documents, please visit <a href={examplesUrl} target="_blank" id="info" rel="noopener noreferrer" aria-label=" What you need  "><strong>here</strong></a>.</p>
                <p>We respect your privacy and will not use your information for any other purpose.</p>
                <p>We only need to see the portion of the document that shows both your name and the information that supports the Sertify category you've selected.</p>
            </div>
        );
    }

    function getPublisherIdIconText(partnerSettings) {
        if (partnerSettings && partnerSettings.publisherIdIconText) {
            return (
                <div>
                    <p>{partnerSettings.publisherIdIconText}</p>
                </div>
            );
        }
    }

    return (
    <div className="account">
        <div className="content">
            <div className="signin">
                <h2>Sertify your business in minutes</h2>
                Already started?  <a href="/login">Sign in.</a>
            </div>
            <div className="instructions">
                <strong>What you need:</strong>
                <ul>
                    <li>
                        {getEligibleDocumentText(partnerSettings)}
                        <InfoIcon 
                            keyName={"ownership"}
                            message={getInfoIconText()}/>
                    </li>
                    <li>
                        Eligible document that shows your identity 
                        <InfoIcon 
                            keyName={"identity"}
                            message={getIdentityInfoIconText()}/>
                    </li>
                    { partnerSettings && partnerSettings.publisherIdReq && (
                        <li>
                            Your {partnerSettings.publisherIdLabel}
                            <InfoIcon 
                                keyName={"identity"}
                                message={getPublisherIdIconText(partnerSettings)}/>
                        </li>
                    )}
                </ul>
            </div>
            <div>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <h3>Business Information</h3>
                    <div className="row-bottom-20" />
                    <Form.Row>
                        <Form.Group as={Col} md="12" controlId="businessName">
                            <Form.Control 
                                autoFocus 
                                type="text" 
                                required 
                                size="sm"
                                maxLength="150"
                                onChange={handleFieldChange} />
                            <Form.Label>Business Name</Form.Label>
                            <Form.Control.Feedback type="invalid">Please enter your business name</Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="dbaName">
                            <Form.Control 
                                type="text" 
                                size="sm" 
                                maxLength="150"
                                onChange={handleFieldChange}/>
                            <Form.Label>Doing Business As (if different)</Form.Label>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="address1">
                            <Form.Control 
                                type="text" 
                                required 
                                size="sm" 
                                maxLength="100"
                                onChange={handleFieldChange} />
                            <Form.Label>Address (primary location)</Form.Label>
                            <Form.Control.Feedback type="invalid">Please enter the address for your business's primary location</Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} md="6" controlId="city" className="pad-right-10">
                            <Form.Control 
                                type="text" 
                                required 
                                size="sm"
                                maxLength="50"
                                onChange={handleFieldChange} />
                            <Form.Label>City</Form.Label>
                            <Form.Control.Feedback type="invalid">Please provide a city</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="3" controlId="state">
                            <AppContext.Provider value={{ handleFieldChange }}>
                                <USStates id="state" isRequired={true} />
                            </AppContext.Provider>
                            <Form.Label>State</Form.Label>
                            <Form.Control.Feedback type="invalid">Please provide a state</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="3" controlId="zip">
                            <Form.Control 
                                type="text" 
                                required 
                                size="sm"
                                maxLength="5"
                                inputMode="tel"
                                onChange={handleFieldChange} />
                            <Form.Label>Zip</Form.Label>
                            <Form.Control.Feedback type="invalid">Please provide a zip</Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>
                    { /* Remove website as of 5/7/2024 
                    <Form.Row>
                        <Form.Group as={Col} controlId="website">
                            <Form.Control 
                                type="text" 
                                required
                                size="sm" 
                                maxLength="500"
                                inputMode="url"
                                onChange={handleFieldChange} />
                            <Form.Label>{partnerSettings && partnerSettings.publisherIdReq ? "URL - eg. website, social media..." : "Website"}</Form.Label>
                            <Form.Control.Feedback type="invalid">Please enter your website</Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>
                    */ }
                    { /* NOTE: form field is still cjPublisherID regardless of publisher */ 
                        partnerSettings && partnerSettings.publisherIdReq && (
                        <Form.Row>
                            <Form.Group as={Col} controlId="cjPublisherId">
                                <Form.Control 
                                    type="text" 
                                    required
                                    size="sm" 
                                    maxLength="100"
                                    onChange={handleFieldChange} />
                                <Form.Label>{partnerSettings.publisherIdLabel}</Form.Label>
                                <Form.Control.Feedback type="invalid">Please enter your {partnerSettings.publisherIdLabel}</Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                    )}
                    <h3>Owner Information</h3>
                    <div className="row-top-5">You can be a sole owner or co-owner of the business</div>
                    <div className="row-bottom-20" />
                    <Form.Row>
                        <Form.Group as={Col} controlId="firstName">
                            <Form.Control 
                                type="text" 
                                required 
                                size="sm"
                                maxLength="50"
                                onChange={handleFieldChange} />
                            <Form.Label>First Name</Form.Label>
                            <Form.Control.Feedback type="invalid">Please enter your first name</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} controlId="lastName">
                            <Form.Control 
                                type="text" 
                                required 
                                size="sm"
                                maxLength="50"
                                onChange={handleFieldChange} />
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control.Feedback type="invalid">Please enter your last name</Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="phone">
                            <Form.Control 
                                type="text" 
                                required 
                                size="sm" 
                                maxLength="50"
                                inputMode="tel"
                                onChange={handleFieldChange} />
                            <Form.Label>Mobile Phone</Form.Label>
                            <Form.Control.Feedback type="invalid">Please enter your contact phone number</Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="email">
                            <Form.Control
                                ref={emailRef}
                                type="email" 
                                required 
                                size="sm" 
                                maxLength="50"
                                autoComplete="new-password"
                                onChange={handleFieldChange} />
                            <Form.Label>Email</Form.Label>
                            <Form.Control.Feedback type="invalid">
                                {
                                    customErrors.email || 'Please enter your email address'
                                }
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="password">
                            <Form.Control
                                ref={passwordRef}
                                type="password" 
                                required 
                                size="sm"
                                maxLength="20"
                                autoComplete="new-password"
                                onChange={handleFieldChange}
                                aria-describedby="passwordHelpBlock" />
                            <Form.Label>Password</Form.Label>
                            <Form.Control.Feedback type="invalid">
                                {
                                    customErrors.passowrd || 'Please create a valid password'
                                }
                            </Form.Control.Feedback>
                            <Form.Text id="passwordHelpBlock" muted>
                                Must be 8-20 characters long, contain an uppercase letter, lowercase letter, and number
                            </Form.Text>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="confirmPassword">
                            <Form.Control
                                ref={confirmPasswordRef}
                                type="password" 
                                required 
                                size="sm" 
                                maxLength="20"
                                autoComplete="new-password"
                                onChange={handleFieldChange} />
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control.Feedback type="invalid">
                                {customErrors.confirmPassword || 'Please re-confirm your password'}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>
                    <div className="pageButtons-single pad-right-10">
                        <Button type="submit" variant="primary">NEXT</Button>
                    </div>
                    
                </Form>
            </div>
        </div>
    </div>
    );
}