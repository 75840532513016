import { Storage } from "aws-amplify";

export async function s3Upload(file) {
    //Since S3 doesn't allow upload of the same file name 
    // - (mobile take photo feature uses the same filename regardless of how many times a user take additional photos)
    // - create a unique filename in S3 that's <file-name>-<random number>.<file-ext>
    var randomNum = Math.floor((Math.random() * 1000) + 1);

    //Get the file name and extension separately
    const name = file.name;
    const lastDot = name.lastIndexOf('.');
    const fileName = name.substring(0, lastDot);
    const fileExt = name.substring(lastDot + 1);

    const filename = `${fileName}-${randomNum}.${fileExt}`;
    console.log("*****storage hello; ")
    const stored = await Storage.vault.put(filename, file, {
        contentType: file.type,
        progressCallback(uploadProgress) {
            console.log(
              "PROGRESS",
              uploadProgress.loaded + "/" + uploadProgress.total
            );
          },
          completeCallback: (event) => {
            console.log("*****storage completed")
            },
          errorCallback: (err) => {
            console.log("*****storage error in errorCallback")
            console.error(err);
            }
    }).catch((e) => {
        console.log("*****storage error; " + e)
    });

    console.log("*****stored.key; " + stored.key)
    return stored.key;
}

export async function s3Delete(key) {
    await Storage.vault.remove(key);
    return;
}