// For Website
export const WEBSITE_VALUE_PREFIX = "https://";
export const WEBSITE_FORM_ID_PREFIX = "website-";
export const WEBSITE_FORM_LABEL = "Website URL"

// For Platform
export const PLATFORM_VALUE_PREFIX = "@";
export const PLATFORM_FACEBOOK_VALUE_PREFIX = "https://facebook.com/";
export const PLATFORM_FORM_ID_PREFIX = "platform-";
export const HANDLE_FORM_LABEL = "Handle"

export const PLATFORM_LIST = [
    {
        "name": "Facebook",
        "displayValue": "Facebook",
        "defaultInputLabel": WEBSITE_FORM_LABEL,
        "defaultInputValue": "https://facebook.com/"
    },
    {
        "name": "Instagram",
        "displayValue": "Instagram",
        "defaultInputLabel": HANDLE_FORM_LABEL,
        "defaultInputValue": "@"
    },
    {
        "name": "Pinterest",
        "displayValue": "Pinterest",
        "defaultInputLabel": HANDLE_FORM_LABEL,
        "defaultInputValue": "@"
    },
    {
        "name": "Snapchat",
        "displayValue": "Snapchat",
        "defaultInputLabel": HANDLE_FORM_LABEL,
        "defaultInputValue": "@"
    },
    {
        "name": "TikTok",
        "displayValue": "TikTok",
        "defaultInputLabel": HANDLE_FORM_LABEL,
        "defaultInputValue": "@"
    },
    {
        "name": "Twitch",
        "displayValue": "Twitch",
        "defaultInputLabel": HANDLE_FORM_LABEL,
        "defaultInputValue": "@"
    },
    {
        "name": "X",
        "displayValue": "X (formerly Twitter)",
        "defaultInputLabel": HANDLE_FORM_LABEL,
        "defaultInputValue": "@"
    },
    {
        "name": "YouTube",
        "displayValue": "YouTube",
        "defaultInputLabel": HANDLE_FORM_LABEL,
        "defaultInputValue": "@"
    },
    {
        "name": "Other",
        "displayValue": "Other",
        "defaultInputLabel": WEBSITE_FORM_LABEL,
        "defaultInputValue": ""
    }
];
