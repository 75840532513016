import React from "react";
import InfoIcon from './InfoIcon';

export default function InstructionsIdentity({sectionName}) {
    
    function getInfoIconText() {
        return (
            <div>
                <p>We need this information to verify your identity.</p>
                <p>We respect your privacy and will not use your information for any other purpose.</p>
                <p>We only need to see the portion of the document that shows both your name and the information that supports the Sertify category you've selected.</p>
            </div>
        );
    }
    
    function getClassificationText(classification) {
        if (classification === 'Women Owned') {
            return "Gender";
        } else if (classification === 'Minority') {
            return "Ethnicity";
        } else if (classification === 'LGBTQ') {
            return "LGBTQ+ Status";
        } else if (classification === 'Veteran Owned') {
            return "Veteran Status";
        } else if (classification === 'Disability') {
            return "Disability Status";
        } 
    }

    function getDisplayHeader(classification) {
        if (classification === 'Women Owned') {
            return "Women Owned";
        } else if (classification === 'Minority') {
            return "Minority Owned";
        } else if (classification === 'LGBTQ') {
            return "LGBTQ+ Owned";
        } else if (classification === 'Veteran Owned') {
            return "Veteran Owned";
        } else if (classification === 'Disability') {
            return "Disability Owned";
        } 
    }

    function getDocumentsList(classification) {
        if (classification === 'Women Owned') {
            return (
                <ul>
                    <li>Driver license</li>
                    <li>Birth certificate</li>
                    <li>Passport</li>
                    <li>WBENC or MWOB certificate</li>
                    <li>Recognized state agency certificate</li>
                    <li>Relevant social media content</li>
                </ul>
            );
        } else if (classification === 'Minority') {
            return (
                <ul>
                    <li>Driver license</li>
                    <li>Ancestry service listing</li>
                    <li>Birth certificate of parent or grandparent</li>
                    <li>Death certificate of parent or grandparent</li>
                    <li>Tribal registry letter</li>
                    <li>NMSDC MBE certificate</li>
                    <li>Recognized state agency certificate</li>
                    <li>Relevant social media content</li>
                </ul>
            );
        } else if (classification === 'LGBTQ') {
            return (
                <ul>
                    <li>Relevant social media content</li>
                    <li>Community letters or media mentions</li>
                    <li>Legal documents with partner</li>
                    <li>Health documents with partner</li>
                    <li>Holdings with name of owner and partner</li>
                    <li>Physician letters</li>
                    <li>LGBTQ certificates/awards/recognitions</li>
                    <li>NGLCC or recognized state agency certificate</li>
                </ul>
            );
        } else if (classification === 'Veteran Owned') {
            return (
                <ul>
                    <li>Relevant social media content</li>
                    <li>DD214</li>
                    <li>Driver license with veteran designation</li>
                    <li>Veteran health ID card</li>
                    <li>Veteran ID card</li>
                    <li>Proof of service letter</li>
                    <li>NVBDC certificate</li>
                    <li>Recognized state agency certificate</li>
                </ul>
            );
        } else if (classification === 'Disability') {
            return (
                <ul>
                    <li>Relevant social media content</li>
                    <li>Driver license with disability designation</li>
                    <li>Vocational rehab specialist document</li>
                    <li>Individualized education program</li>
                    <li>Government disability benefits</li>
                    <li>Disability:IN certification of disability</li>
                    <li>Recognized state agency certificate</li>
                </ul>
            );
        } 
    }

    return (
        <>
        {
            <>
            <div>
                <h3>Confirm Identity - {getDisplayHeader(sectionName)}</h3>
            </div>
            <div>
                We need a document that shows <strong>Owner Name</strong> and <strong>{getClassificationText(sectionName)}</strong>. Examples: 
                <InfoIcon 
                    keyName={"identity"}
                    message={getInfoIconText()}
                />
            </div>
            <div>
                {getDocumentsList(sectionName)}
            </div>
            </>
        }
        </>
    );
}