import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./App.css";
import Routes from "./Routes";
import { AppContext } from "./libs/contextLib";
import { API } from "aws-amplify";
import { Auth } from "aws-amplify";
import Header from "./components/Header";
import Footer from "./components/Footer";
import LoaderContext, { LoaderProvider } from "./context/LoaderContext";
import { FullPageLoader } from "./components/Loader";

function App() {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [classifications, setClassifications] = useState([]);
  const [application, setApplication] = useState(null);
  const [applicationReloaded, setIsApplicationReloaded] = useState(false);
  
  const history = useHistory();

  useEffect(() => {
    //ensures window scrolls to top when refreshing page
    window.scrollTo(0, 0);
    
    /**
     * Check if session is authenticated
     */
    async function onLoad() {
      try {
        const currentUserInfo = await Auth.currentUserInfo();
        //console.log("****in App.js: onLoad() currentUserInfo: " + JSON.stringify(currentUserInfo));
        
        //If user signed in successfully, get application associated with user
        //NOTE: only consider user authenticated IF 1) User has successful login session AND 2) User has an application
        if (currentUserInfo) {
          //Get existing application for user if available
          const application = await loadApplication();
          if (application) {
            //console.log("*****loaded application: " + application.applicationId);
            setApplication(application);
            setIsApplicationReloaded(true);
            setClassifications(application.classifications);
            userHasAuthenticated(true);
          } 
        } 
      }
      catch (e) {
        //if error, redirect user back to sign up page since we cannot process crucial information
        history.push("/");
      }
      //Important!  update isAuthenticating to false so that App.js can continue rendering the children components
      setIsAuthenticating(false);
    }

    onLoad();
  }, [isAuthenticated, applicationReloaded]);  // eslint-disable-line react-hooks/exhaustive-deps
  
  
  function loadApplication() {
    //list application that the user belongs to
    return API.get("applications", "/applications/getDefaultApplication");
  }
  
  return (
    !isAuthenticating && (
      <div className="App">
        <div className="mainContent">
          <AppContext.Provider
            value={{ isAuthenticated, userHasAuthenticated, classifications, setClassifications, application, setApplication, setIsApplicationReloaded }}
          >
            <LoaderProvider>
              <LoaderContext.Consumer>
                {
                  ([loading]) => (
                    <>
                      <Header />
                      <Routes />
                      { loading && <FullPageLoader /> }
                    </>
                  )
                }
              </LoaderContext.Consumer>
            </LoaderProvider>
              
          </AppContext.Provider>
        </div>
        <Footer />
      </div>
    )
  );
}

export default App;