import React, {useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { onError } from "../libs/errorLib";
import { useHistory } from "react-router-dom";
import { useAppContext } from "../libs/contextLib";
import { getPartnerSettings } from "../libs/utilsLib"
import { API } from "aws-amplify";
import "./Classifications.css";
import { useLoader } from "../context/LoaderContext";
import CheckBox from "../components/CheckBox";

export default function Classifications() {
    const [validated, setValidated] = useState(false);
    const [showError, setShowError] = useState(false);
    const [isProcessing, setIsProcessing] = useState(true);
    const [, setLoading] = useLoader();
    const [classifications, setClassifications] = useState([]);
    const [, setPartnerSettings] = useState(null);
    const [businessLabel, ] = useState("Business");
    const { application, setIsApplicationReloaded } = useAppContext();
    const history = useHistory();

    useEffect(() => {
        //ensures window scrolls to top when loading this page
        window.scrollTo(0, 0);
        
        //Determine which classifications user is applying for
        async function onLoad() {
            try {
                setIsProcessing(true);

                if (application.classifications && application.classifications.length > 0) {
                    setClassifications(application.classifications);
                    setValidated(true);
                }

                if (application.partnerCode) {
                    // Retrieve partner settings
                    const partnerSettings = getPartnerSettings(application.partnerCode);
                    if (partnerSettings) {
                        setPartnerSettings(partnerSettings);
                    }
                }
                
                setIsProcessing(false);
            } catch (e) {
                onError(e);
            }
        }
        onLoad();
    }, []);  // eslint-disable-line react-hooks/exhaustive-deps

    /*
    useEffect(() => {
        if (partnerSettings && partnerSettings.businessLabel) {
            setBusinessLabel(partnerSettings.businessLabel);
        }
    }, [partnerSettings]);  
    */
    useEffect(() => {
        setLoading(isProcessing);
    }, [isProcessing, setLoading]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsProcessing(true);
        
        //make sure at least one classification is select
        if (classifications && classifications.length < 1) {
            setValidated(false);
            setShowError(true);
            return;
        } else {
            //get donationType based on the selected Org value (more for tracking purposes)
            const params = {
                classifications: classifications
            };
            //1. save classifications
            await saveApplication(params);
            //force application to reload
            setIsApplicationReloaded(false);
            setIsProcessing(false);
            history.push("/websites");
            //history.push("/ownership");
        }
    };

    function saveApplication(params) {
        return API.patch("applications", "/applications/" + application.applicationId, {
            body: params,
        });
    }

    const handleCheck = (name, checked) => {
        const index = classifications.indexOf(name);
        let update = [...classifications];

        if (checked) {
            if(index === -1) {
                //only push if array does not have item already
                update.push(name);
            }
        } else {
            if (index >= 0) {
                //remove from array
                update.splice(index, 1);
            }
        }
        if (update && update.length < 1) {
            setValidated(false);
        } else {
            setValidated(true);
        }
        setClassifications(update);
    };

    return (
        <div className="content">
            { !isProcessing && (
                <div className="classifications">
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <div className="instructions">
                        <div>
                            <h3>How do you identify your business? (select all that apply)</h3>
                        </div>
                    </div>
                        <Form.Group controlId="formDisability">
                            <Form.Row>
                                <Col xs={12}>
                                    <CheckBox
                                        checked={classifications && classifications.includes('Disability')}
                                        onChange={value => handleCheck('Disability', value)}
                                    >
                                        Disability Owned {businessLabel}
                                    </CheckBox>
                                </Col>
                            </Form.Row>
                        </Form.Group>
                        <Form.Group controlId="formLGBTQ">
                            <Form.Row>
                                <Col xs={12}>
                                    <CheckBox
                                        checked={classifications && classifications.includes('LGBTQ')}
                                        onChange={value => handleCheck('LGBTQ', value)}
                                    >
                                        LGBTQ+ Owned {businessLabel}
                                    </CheckBox>
                                </Col>
                            </Form.Row>
                        </Form.Group>
                        <Form.Group controlId="formSmall">
                            <Form.Row>
                                <Col xs={12}>
                                    <CheckBox
                                        checked={classifications && classifications.includes('Small Business')}
                                        onChange={value => handleCheck('Small Business', value)}
                                    >
                                        Small Business (&lt;250 employees)
                                    </CheckBox>
                                </Col>
                            </Form.Row>
                        </Form.Group>
                        <Form.Group controlId="formVeteran" size="lg">
                            <Form.Row>
                                <Col xs={12}>
                                    <CheckBox
                                        checked={classifications && classifications.includes('Veteran Owned')}
                                        onChange={value => handleCheck('Veteran Owned', value)}
                                    >
                                        Veteran Owned {businessLabel}
                                    </CheckBox>
                                </Col>
                            </Form.Row>
                        </Form.Group>
                        <Form.Group controlId="formWomen">
                            <Form.Row>
                                <Col xs={12}>
                                    <CheckBox
                                        checked={classifications && classifications.includes('Women Owned')}
                                        onChange={value => handleCheck('Women Owned', value)}
                                    >
                                        Woman Owned {businessLabel}
                                    </CheckBox>
                                </Col>
                            </Form.Row>
                        </Form.Group>
                        <Form.Group controlId="formMinority">
                            <Form.Row>
                                <Col xs={12}>
                                    Minority Owned {businessLabel}
                                </Col>
                            </Form.Row>
                        </Form.Group>
                            { /* Sub section for Minority */ }
                            <Form.Group controlId="formBlackOwned">
                                <Form.Row>
                                    <Col xs={1}></Col>
                                    <Col xs={11}>
                                        <CheckBox
                                            checked={classifications && classifications.includes('Asian')}
                                            onChange={value => handleCheck('Asian', value)}
                                        >
                                            Asian Owned
                                        </CheckBox>
                                    </Col>
                                </Form.Row>
                            </Form.Group>
                            <Form.Group controlId="formAsianOwned">
                                <Form.Row>
                                    <Col xs={1}></Col>
                                    <Col xs={11}>
                                        <CheckBox
                                            checked={classifications && classifications.includes('Black Owned')}
                                            onChange={value => handleCheck('Black Owned', value)}
                                        >
                                            Black Owned
                                        </CheckBox>
                                    </Col>
                                </Form.Row>
                            </Form.Group>
                            <Form.Group controlId="formHispanicOwned">
                                <Form.Row>
                                    <Col xs={1}></Col>
                                    <Col xs={11}>
                                        <CheckBox
                                            checked={classifications && classifications.includes('Hispanic')}
                                            onChange={value => handleCheck('Hispanic', value)}
                                        >
                                            Hispanic Owned
                                        </CheckBox>
                                    </Col>
                                </Form.Row>
                            </Form.Group>
                            <Form.Group controlId="formNativeAmericanOwned">
                                <Form.Row>
                                    <Col xs={1}></Col>
                                    <Col xs={11}>
                                        <CheckBox
                                            checked={classifications && classifications.includes('Native American')}
                                            onChange={value => handleCheck('Native American', value)}
                                        >
                                            Native American Owned
                                        </CheckBox>
                                    </Col>
                                </Form.Row>
                            </Form.Group>
                        { showError && 
                            <div className="error">
                                Please choose at least one classification.
                            </div>
                        }
                        <div className="contentBody">
                            <div className="pageButtons-single">
                                <Button variant="primary" type="submit" disabled={!validated}>
                                    NEXT
                                </Button>
                            </div>
                        </div>
                    </Form>
                </div>
            
            )}
        </div>
    );
}