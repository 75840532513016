import React from "react";
import "./Footer.css";

export default function Footer() {
    
    return (
        <footer className="Footer">
            <div >
                <div>
                    <div className="footer-container">
                        <ul className="footer-menu">
                            <li>
                                <span aria-label=" Made in NYC " >
                                        Made in NYC 
                                </span>
                            </li> 
                            <li>
                                |
                            </li> 
                            <li>
                                <span>
                                    <a href="https://sertify.io/terms" target="_blank" id="requestPrivacyNotice" rel="noopener noreferrer" aria-label=" Terms of use  ">
                                        Terms 
                                    </a>
                                </span>
                            </li> 
                            <li>
                                |
                            </li> 
                            <li>
                                <span id="requestTermsOfUse-link-wrapper">
                                    <a href="https://sertify.io/privacy" target="_blank" id="requestTermsOfUse" rel="noopener noreferrer" aria-label=" Privacy ">
                                        Privacy
                                    </a>
                                </span>
                            </li> 
                            <li>
                                |
                            </li> 
                            <li>
                                <span>
                                    <a href="https://sertify.io/accessibility" target="_blank" id="requestPrivacyNotice" rel="noopener noreferrer" aria-label=" Accessibility  ">
                                        Accessibility 
                                    </a>
                                </span>
                            </li> 
                            <li>
                                |
                            </li> 
                            <li>
                                <span aria-label=" Copyright Sertify, Inc." >
                                    &copy; 2024 Sertify, Inc. 
                                </span>
                            </li> 
                        </ul> 
                    </div>
                </div>
            </div>
        </footer>
    );
}