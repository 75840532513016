const CDN_BASE_URL = 'https://sert-consumer.s3.amazonaws.com/images/classifications';

const classificationsDisplayMap = {
    "Minority": {
        image:`${CDN_BASE_URL}/minority_sb.png`, 
        shortText: "Minority Owned",
        fullText: "Minority Owned Small Business"
    },
    "Black Owned": {
        image:`${CDN_BASE_URL}/blackowned_sb.png`, 
        shortText: "Black Owned",
        fullText: "Black Owned Small Business"
    },
    "Asian": {
        image:`${CDN_BASE_URL}/asian_sb.png`, 
        shortText: "Asian Owned",
        fullText: "Asian Owned Small Business"
    },
    "Hispanic": {
        image:`${CDN_BASE_URL}/hispanic_sb.png`, 
        shortText: "Hispanic Owned",
        fullText: "Hispanic Owned Small Business"
    },
    "Native American": {
        image:`${CDN_BASE_URL}/nativeamerican_sb.png`, 
        shortText: "Native American Owned",
        fullText: "Native American Owned Small Business"
    },
    "LGBTQ": {
        image:`${CDN_BASE_URL}/lgbtq_sb.png`, 
        shortText: "LGBTQ Owned",
        fullText: "LGBTQ Owned Small Business"
    }, 
    'Women Owned': {
        image:`${CDN_BASE_URL}/women_sb.png`, 
        shortText: "Women Owned",
        fullText: "Women Owned Small Business"
    }, 
    "Disability": {
        image:`${CDN_BASE_URL}/disability_sb.png`,
        shortText: "Disability Owned",
        fullText: "Disability Owned Small Business"
    }, 
    "Veteran Owned": {
        image:`${CDN_BASE_URL}/veteran_sb.png`,
        shortText: "Veteran Owned",
        fullText: "Veteran Owned Small Business"
    }, 
    "Small Business": {
        image:`${CDN_BASE_URL}/smallbusiness_sb.png`,
        shortText: "Small Business",
        fullText: "Small Business"
    }, 
};

export default classificationsDisplayMap;
