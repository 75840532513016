import React, {useEffect, useState} from "react";
import { useHistory } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { AppContext, useAppContext } from "../libs/contextLib";
import { API } from "aws-amplify";
import { onError } from "../libs/errorLib";
import FileUpload from "../components/FileUpload";
import InstructionsIdentity from "../components/InstructionsIdentity";
import { getQueryString } from "../utils/Helper";
import "./Identity.css";

export default function Identity() {
    const [isProcessing, setIsProcessing] = useState(false);
    const [validated, setIsValidated] = useState(false);
    const [currentSection, setCurrentSection] = useState(null); 
    const [sectionsNeeded, setSectionsNeeded] = useState([]);
    const [files, setFiles] = useState([]);
    const { application, classifications } = useAppContext();
    const history = useHistory();
    const isFromNextSection = getQueryString("back");

    useEffect(() => {
        //ensures window scrolls to top when loading this page
        window.scrollTo(0, 0);

        async function onInitialize() {
            setIsProcessing(true);
            
            //On initial of Identity component, we should determine which Identity sections are needed
            //Look at Classifications to determine which Identity sections are needed
            //  Note: The order of display will be determined by:
            //      - if user came from "Next" button on section before Identity section
            //      - OR "Previous" button on section after Identity section
            //          - If coming from Previuos button on next section, use query parameter to determine initial "currentSection" value
            if (sectionsNeeded === null || sectionsNeeded.length < 1) {
                initializeSectionsNeeded(isFromNextSection);
            }
            setIsProcessing(false);
        }

        //Determine which documents are needed based on currentSection / classification list
        //load the files list and set the files list based on the currentSection type (i.e. files related to veteran, or disability)
        async function onLoad() {
            try {
                setIsProcessing(true);
                setIsValidated(false);

                //Load files from application
                const applicationFilesList = await loadFiles();
  
                //filter files list based on current section
                if (applicationFilesList && applicationFilesList.length > 0) {
                    const filteredFilesList = applicationFilesList.filter(file => file.documentType === currentSection);
                    setFiles(filteredFilesList.map(file => {
                        var obj = {};
                        obj.fileName = file.S3FileName;
                        obj.documentId = file.documentId;
                        return obj;
                    }));
                    if (filteredFilesList.length > 0) {
                        setIsValidated(true);
                    }
                }
                setIsProcessing(false);
            } catch (e) {
                onError(e);
            }
        }

        if(currentSection === undefined || currentSection === null) {
            onInitialize();
        } else if ( currentSection === 'none') {
            //this use case is if "smallBusiness" is the only classification selected, then no identity information is required
            if (isFromNextSection) {
                history.push("/ownership");
            } else {
                history.push("/payment");
            }
        } else {
            //only load identity information if we have a currentSection set
            onLoad();
        }

    }, [currentSection]);  // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Helper function to sort out Identity display sections based on a certain sequence
     * @param {array} sections 
     */
    function customDisplaySorting(sections) {
        var preferredSortOrder = ['Women Owned','Minority','LGBTQ', 'Disability', 'Veteran Owned']; 
        sections.sort(
            function(a, b) {                              
                if (a === b) {             
                    return a.name.localeCompare(b.name); 
                } else {                              
                    return preferredSortOrder.indexOf(a) - preferredSortOrder.indexOf(b); 
                }
            }
        );
    }

    /**
     * Helper function to determine which Identity information is needed and rendered.  
     * For each identity section, we would need a set of instructions and corresponding documents 
     */
    function initializeSectionsNeeded(isFromNextSection) {
        //We'll always need gender section since we need the basic documents like a picture ID
        var sectionsNeeded = [];
        //sectionsNeeded.push('gender');

        classifications && classifications.forEach(classification => {
            //Check which additional sections need to be rendered based on Classifications that applicant is applying for
            if (!sectionsNeeded.includes('Women Owned') && classification === 'Women Owned') {
                sectionsNeeded.push("Women Owned");
            }
            if (!sectionsNeeded.includes('Minority') && 
                (classification === 'Minority' || 
                classification === 'Asian' || classification === 'Hispanic' || 
                classification === 'Black Owned' || classification === 'Native American')
            ) {
                sectionsNeeded.push("Minority");
            }
            if (!sectionsNeeded.includes('LGBTQ') && classification === 'LGBTQ') {
                sectionsNeeded.push("LGBTQ");
            }
            if (!sectionsNeeded.includes('Disability') && classification === 'Disability') {
                sectionsNeeded.push("Disability");
            }
            if (!sectionsNeeded.includes('Veteran Owned') && classification === 'Veteran Owned') {
                sectionsNeeded.push("Veteran Owned");
            }
        });
        //Sort sections display based on our preferred flow
        customDisplaySorting(sectionsNeeded);
        setSectionsNeeded(sectionsNeeded); 

        //Determine initial currentSection value
        //If the user came from next section, then set currentSection as the last element of the sectionsNeeded array
        //Initialize currentSection to the first section of preferred sorting
        if (sectionsNeeded.length > 0) {
            if (isFromNextSection) {
                setCurrentSection(sectionsNeeded[sectionsNeeded.length-1]);
            } else {
                setCurrentSection(sectionsNeeded[0]);
            }
        } else {
            setCurrentSection("none");
        }
    }

    function loadFiles() {
        return API.get("applications", "/applications/" + application.applicationId + "/documents", {});
    }

    function goBack() {
        //Custom logic:
        //  - sectionsNeeded will tell us the various Identity sections the user has to go through
        //  - use the current section to find the index number in the sectionsNeeded array.  
        //      - If there is a previous index in sectionsNeeded, then set currentSection as the previous index to re-render section
        //      - Else, go to previous section (Ownership)
        var currentIndex = sectionsNeeded.indexOf(currentSection);
        
        if (sectionsNeeded[currentIndex - 1] === undefined) {
            history.push("/ownership");
        } else {
            setCurrentSection(sectionsNeeded[currentIndex - 1]);
        }
    }

    function goNext() {
        //Custom logic:
        //  - sectionsNeeded will tell us the various Identity sections the user has to go through
        //  - use the current section to find the index number in the sectionsNeeded array.  
        //      - If there is a next index in sectionsNeeded, then set currentSection as the next index to re-render section
        //      - Else, go to next section (Payment)
        var currentIndex = sectionsNeeded.indexOf(currentSection);
        
        if (sectionsNeeded[currentIndex + 1] === undefined) {
            history.push("/payment");
        } else {
            setCurrentSection(sectionsNeeded[currentIndex + 1]);
        }
    }

    return (
        <div className="content">
            { !isProcessing && currentSection && currentSection !== 'none' && (
                <div>
                    <div className="contentBody">
                        <InstructionsIdentity sectionName={currentSection} />
                    </div>
                    <div className="contentBody">
                        <AppContext.Provider
                            value={{ application, files, setFiles, setIsValidated }}
                        >
                            <FileUpload sectionName={currentSection} />
                        </AppContext.Provider>
                    </div>
                    <div className="contentBody">
                        <div className="pageButtons-multiple">
                            <Button variant="secondary" onClick={goBack} disabled={isProcessing}>BACK</Button>
                            <Button variant="primary" onClick={goNext}  disabled={!validated} >NEXT</Button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}