import React, { useEffect } from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { useAppContext } from "../libs/contextLib";

export default function AuthenticatedRoute({ children, ...rest }) {
    const { pathname, search } = useLocation();
    const { isAuthenticated } = useAppContext();
    const { application } = useAppContext();
    
    useEffect(() => {
        //console.log("*****IN AuthenticatedRoute: application: " + JSON.stringify(application));
        //TODO: check 
    });  

    return (
        <Route {...rest}>
        {
            //If not authenticated or no application found, redirect to home 
            (!isAuthenticated || !application) && (
                <Redirect to={
                    `/?redirect=${pathname}${search}`
                } />
            )
        }
        {
            //If application is in draft or additionalInfo state, render children
            application && 
                (application.applicationStatus === 'draft' || application.applicationStatus === 'requestDocs') 
                && (
                children
            )
        }
        {
            //If application is submitted/approved/denied, go to Success page
            application && 
                (
                    (application.applicationStatus === 'submitted' || application.applicationStatus === 'denied' || application.applicationStatus === 'approved')
                )
                && (
                <Redirect to={"/success"} />
            )
        }
        </Route>
    );
}