import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { LinkContainer } from "react-router-bootstrap";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
// import Nav from "react-bootstrap/Nav";
// import Row from "react-bootstrap/Row";
// import config from "../config";
import { API } from "aws-amplify";
// import { loadStripe } from '@stripe/stripe-js';
import { useAppContext } from "../libs/contextLib";
import { getSortedDecalForDisplay, validatePremiumPartner } from "../libs/utilsLib"
import classificationsDisplayMap from "../constants/classificationsDisplayMap";
import "./Payment.css";

export default function Payment() {
    const [validated, setValidated] = useState(false);
    const { application, classifications, setIsApplicationReloaded } = useAppContext();
    const { isAuthenticated } = useAppContext();
    const [, setDecalTemplateImageUrl] = useState("");

    // 3 types of plan
    //  1. premium - small business has to pay on their own with stripe.  standard plan
    //  2. premiumPartner - small business doesn't have to pay, since subscription was paid by a partner.  Same as premium except it's free to the applicant
    //  3. basic - free plan.  temporarily not available 
    const [plan, setPlan] = useState("basic");
    const [, setCoupon] = useState(null);
    const [planOriginalPrice] = useState(180);
    const [, setPlanFinalPrice] = useState(180);
    // const stripePromise = loadStripe(config.STRIPE_KEY);
    
    useEffect(() => {
        //ensures window scrolls to top when loading this page
        window.scrollTo(0, 0);
        
        //Check classifications to determine which decal to show as template
        if (classifications && classifications.length > 0) {
            var sortedPreferredClassifications = getSortedDecalForDisplay(classifications);
            var classification = sortedPreferredClassifications[0];
            var decalTemplateImageUrl = classificationsDisplayMap[classification].image;
            setDecalTemplateImageUrl(decalTemplateImageUrl);
        }

        // Determine if this application is a premiumPartner plan
        // premium partner plan has a valid partnerCode + advertiser combo

        /* as of 5/2024, no longer charging applicants */
        let plan = 'basic';
        if (application.partnerCode || application.advertiser) {
            const isPremiumPartner = validatePremiumPartner(application.partnerCode, application.advertiser);
            if (isPremiumPartner) {
                plan = 'premiumPartner';
                setPlan('premiumPartner');
            }
        }

        const getStripeDiscountByPartner = async (couponCode) => {
            const { result } = await API.get('stripe', '/couponLookup', {
                'queryStringParameters': {
                    'couponCode': couponCode
                }
            });
            
            // Determine if there is a discount that should be applied
            if (result) {
                setCoupon(result);

                // Determine if the discount is a fixed amount off or percent off
                let newPrice = planOriginalPrice;
                if (result.amountOff !== null) {
                    newPrice = planOriginalPrice - result.amountOff/100;   // stripe's amount off is in cents
                } else if (result.percentOff !== null) {
                    newPrice = planOriginalPrice * (1 - result.percentOff/100); // stripe's percent off is the number, so need to get the actual %
                }
                setPlanFinalPrice(Math.round(newPrice));
            }            
        };

        // For premium plans, see if there's a discount available on pricing based on partner
        // For premium partner plans, it'll be free
        if (plan === 'premium') {
            getStripeDiscountByPartner(application.partnerCode)
        } else {
            setPlanFinalPrice(0);
        }
    }, [isAuthenticated]);  // eslint-disable-line react-hooks/exhaustive-deps

    /*
    const fetchCheckoutSession = async () => {
        return API.post("stripe", "/checkout", {
            body: {
                applicationId: application.applicationId,
                businessName: application.businessName,
                userId: application.userId,
                email: application.email,
                partnerCode: application.partnerCode,
                domainUrl: window.location.origin
            },
        });
    }
    */ 
    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        // get checkbox data (termsData checkboxes)
        const formData = new FormData(event.target),
                termsData = Object.fromEntries(formData.entries())
        if (form.checkValidity() === false) {
            setValidated(true);
            return;
        }

        setValidated(true);

        /* as of 5/2024, no longer charging applicants */
        const params = {
            applicationId: application.applicationId,
            termsData: termsData,
        };
        await submitApplication(params);

        //force application to reload
        setIsApplicationReloaded(false);
    };

    function submitApplication(params) {
        return API.post("applications", "/applications/submit", {
            body: params,
        });
    }

    /* 
    const handlePlanSelect = (eventKey) => {
        setPlan(eventKey);
    }

    const getDiscountText = (coupon) => {
        if (coupon) {
            let text = coupon.name;
            if (coupon.amountOff !== null) {
                text = text + " ($" + coupon.amountOff + " off)";
            } else if (coupon.percentOff !== null) {
                text = text + " (" + coupon.percentOff + "% off)";
            }
            return text;
        }
        return "";
    }

    // Hardcoding for now
    const getPremiumPartnerText = (partner, advertiser) => {
        if (advertiser && advertiser.toLowerCase() === 'cvs') {
            if (advertiser.toLowerCase() === 'cvs') {
                return 'Compliments of CVS (100% off)';
            } 
            return 'Compliments of ' + capitalizeFirstLetter(advertiser) + " (100% off)";
        }
        if (partner) {
            if (partner.toLowerCase() === 'openx') {
                return 'Compliments of OpenX (100% off)';
            } 
            return 'Compliments of ' + capitalizeFirstLetter(partner) + " (100% off)";
        }
    }
    
    function renderPlanDetails() {
        return (
            <div>
                { (plan === 'premium' || plan === 'premiumPartner') && (
                    <div>
                        <Row className="row-top-20">
                            <Col xs={2}>
                                <img src={decalTemplateImageUrl} alt="Sertify Stamp" />
                            </Col>
                            { (application.partnerCode === undefined || application.partnerCode === null) && (
                                <Col xs={8}>
                                    <ul className='ul-block-start-none'>
                                        <li><strong>Your Marketing</strong> - Sertify Stamps for your storefront, website, Facebook, Instagram, Yelp, Google, and email marketing</li>
                                        <li><strong>Sertify Marketing</strong> - Premium listing in the Sertify Directory with a custom profile, and social media highlights</li>
                                        <li><strong>Partner Marketing</strong> - Small business customers of our partners will be highlighted in their communication channels</li>
                                        <li>5% of our proceeds are donated to related non-profits </li>
                                    </ul>
                                </Col>
                            )}
                            { (application.partnerCode && (application.partnerCode === 'cj' && !application.advertiser )) && (
                                <Col xs={8}>
                                    <ul className='ul-block-start-none'>
                                        <li><strong>CJ Marketing</strong> - Get discovered by the world’s largest brands who can easily discover, connect and promote you</li>
                                        <li><strong>Your Marketing</strong> - Sertify Stamps for your storefront, website, Facebook, Instagram, Yelp, Google, and email marketing</li>
                                        <li><strong>Giveback</strong> - 5% of proceeds are donated to related non-profits like Black Girl Ventures, True Colors United, National Veteran Small Business Coalition</li>
                                    </ul>
                                </Col>
                            )}
                            { (application.partnerCode && (application.partnerCode === 'cj' && application.advertiser === 'expedia')) && (
                                <Col xs={8}>
                                    <ul className='ul-block-start-none'>
                                        <li><strong>Expedia Group</strong> - Get discovered by one of the world’s largest travel brands.  You could be included in the Q1 investment campaign and 2023 inclusion strategy</li>
                                        <li><strong>Your Marketing</strong> - Sertify Stamps for your storefront, website, Facebook, Instagram, Yelp, Google, and email marketing</li>
                                    </ul>
                                </Col>
                            )}
                            { (application.partnerCode && (application.partnerCode === 'cj' && application.advertiser === 'cvs')) && (
                                <Col xs={8}>
                                    <ul className='ul-block-start-none'>
                                        <li><strong>CVS</strong> - Get special promotions & offers and hear about new products & services</li>
                                        <li><strong>Your Marketing</strong> - Sertify Stamps for your storefront, website, Facebook, Instagram, Yelp, Google, and email marketing</li>
                                    </ul>
                                </Col>
                            )}
                            { (application.partnerCode && application.partnerCode === 'shareasale') && (
                                <Col xs={8}>
                                    <ul className='ul-block-start-none'>
                                        <li><strong>ShareASale Marketing</strong> - Get discovered by 240,000+ publishers who can easily search, discover and connect with you</li>
                                        <li><strong>Your Marketing</strong> - Sertify Stamps for your storefront, website, Facebook, Instagram, Yelp, Google, and email marketing</li>
                                    </ul>
                                </Col>
                            )}
                            { (application.partnerCode && application.partnerCode === 'raptive') && (
                                <Col xs={8}>
                                    <ul className='ul-block-start-none'>
                                        <li><strong>Raptive</strong> - Will highlight diverse-owned businesses to drive more spend by advertiser</li>
                                        <li><strong>Your Marketing</strong> - Sertify Stamps for your storefront, website, Facebook, Instagram, Yelp, Google, and email marketing</li>
                                    </ul>
                                </Col>
                            )}
                            { (application.partnerCode && application.partnerCode === 'openx') && (
                                <Col xs={8}>
                                    <ul className='ul-block-start-none'>
                                        <li><strong>OpenX</strong> - Will highlight diverse-owned businesses to drive more spend by advertiser</li>
                                        <li><strong>Your Marketing</strong> - Sertify Stamps for your storefront, website, Facebook, Instagram, Yelp, Google, and email marketing</li>
                                    </ul>
                                </Col>
                            )}
                            <Col className="col" xs={1}><strong>&nbsp;${planOriginalPrice}</strong></Col>
                        </Row>
                        { plan === 'premiumPartner' && (
                        <Row>
                            <Col xs={2}></Col>
                            <Col xs={8}><strong>{getPremiumPartnerText(application.partnerCode, application.advertiser)}</strong></Col>
                            <Col className="col " xs={1}><strong>-${Math.round(planOriginalPrice - planFinalPrice)}</strong></Col>
                        </Row>
                        )}
                        { plan !== 'premiumPartner' && coupon && (
                        <Row>
                            <Col xs={2}></Col>
                            <Col xs={8}><strong>{getDiscountText(coupon)}</strong></Col>
                            <Col className="col " xs={1}><strong>-${Math.round(planOriginalPrice - planFinalPrice)}</strong></Col>
                        </Row>
                        )}
                        <hr/>
                        <Row>
                            <Col xs={2}></Col>
                            <Col xs={8}><strong>Total</strong></Col>
                            <Col className="col priceHighlight" xs={1}><strong>&nbsp;${planFinalPrice}</strong></Col>
                        </Row>
                    </div>
                )}
                { plan === 'basic' && (
                    <div>
                        <Row className="row-top-20">
                            <Col xs={2}>
                                <img src={decalTemplateImageUrl} alt="Sertify Stamp"/>
                            </Col>
                            <Col xs={8}>
                                <strong>Basic Plan</strong>
                                <ul>
                                <li>All relevant Sertifications</li>
                                <li>Listing in the Sertify Directory</li>
                                </ul>
                            </Col>
                            <Col className="rightJustified" xs={1}><strong>Free</strong></Col>
                        </Row>
                        <hr/>
                        <Row>
                            <Col xs={2}></Col>
                            <Col xs={8}><strong>Total</strong></Col>
                            <Col className="" xs={2}><strong>&nbsp;$0</strong></Col>
                        </Row>
                    </div>
                )}
            </div>
        );
    }
    */
    return (
        <div className="payment">
            <div className="content">
                {/*
                <div className="payment">
                    <Nav fill variant="tabs" activeKey={plan} onSelect={handlePlanSelect}>
                        <Nav.Item>
                            <Nav.Link eventKey="basic">Basic</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="premium">Premium</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    
                    {renderPlanDetails()}
                </div>
                */}
                <h3>Since brands have different advertising requirements for diverse-owned spend, please check all that apply:</h3>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    
                    <div className="payment">
                        <div className="row-bottom-20"></div>
                        <Form.Group className="row-bottom-10" controlId="formAgreeEmployeesSize">
                            <Form.Check>
                                <Form.Check.Input type="checkbox" name="formAgreeEmployeesSize" />
                                <Form.Check.Label className="pad-left-5">My business is less than 250 employees.</Form.Check.Label>
                            </Form.Check>
                        </Form.Group>
                        <Form.Group className="row-bottom-10" controlId="formAgreeOwnership">
                            <Form.Check>
                                <Form.Check.Input type="checkbox" name="formAgreeOwnership" />
                                <Form.Check.Label className="pad-left-5">I owned and operated 51% of the business.</Form.Check.Label>
                            </Form.Check>
                        </Form.Group>
                        <Form.Group className="row-bottom-10" controlId="formAgreeCitizen">
                            <Form.Check>
                                <Form.Check.Input type="checkbox" name="formAgreeCitizen" />
                                <Form.Check.Label className="pad-left-5">I am a U.S. citizen.</Form.Check.Label>
                            </Form.Check>
                        </Form.Group>
                        <Form.Group className="row-bottom-10" controlId="formAgreeDirectRelationship">
                            <Form.Check>
                                <Form.Check.Input type="checkbox" name="formAgreeDirectRelationship" />
                                <Form.Check.Label className="pad-left-5">My business has direct relationships with consumers. It does not aggregate other businesses or operate as an agent for other businesses.</Form.Check.Label>
                            </Form.Check>
                        </Form.Group>
                        <Form.Group className="row-bottom-10" controlId="formAgreeIndependent">
                            <Form.Check>
                                <Form.Check.Input type="checkbox" name="formAgreeIndependent" />
                                <Form.Check.Label className="pad-left-5">My business is independent and not part of a franchise or holding company.</Form.Check.Label>
                            </Form.Check>
                        </Form.Group>
                        <div className="mobilePadding" />
                        <hr />
                        <div className="row-bottom-30" />
                        <Form.Group controlId="formAgreeTerms" >
                            <Form.Check>
                                <Form.Check.Input required type="checkbox" />
                                <Form.Check.Label className="pad-left-5">I agree to the <a href="https://sertify.io/terms" target="_blank" rel="noopener noreferrer">Terms and Conditions</a>.</Form.Check.Label>
                                <Form.Control.Feedback className="pad-left-5" type="invalid">Please agree before submitting.</Form.Control.Feedback>
                            </Form.Check>
                        </Form.Group>
                        <div className="signature row-top-10">
                            <Form.Group as={Col} controlId="formSignature">
                                <Form.Control size="sm" required maxLength="100"/>
                                <Form.Label><strong>Signature</strong> <i>&nbsp;(Print your name)</i></Form.Label>
                            </Form.Group>
                        </div>
                    
                    </div>
                    <div className="pageButtons-multiple">
                        <div>
                        <LinkContainer key="back" to="/identity?back=t">
                            <Button variant="secondary">BACK</Button>
                        </LinkContainer>
                        </div>
                        <div>
                        <Button variant="primary" type="submit">NEXT</Button>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    );
}