import React from 'react';
import './Loader.scss';

const Spinner = () => {
  return (
    <div className="spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};


const Loader = () => (
  <div className="loader">
    <Spinner />
  </div>
);

export const FullPageLoader = () => (
  <div className="full-page-loader">
    <Spinner />
  </div>
);

export default Loader;
