import React from "react";
import "./Steps.css";
import Stepper from 'react-stepper-horizontal'

export default function Steps({
    activeTab = "account",
    ...props
}) {
    const steps = [
        {title: 'Create Account'}, 
        {title: 'Confirm Ownership'}, 
        {title: 'Confirm Identity'}, 
        {title: 'Complete Application'}
    ];

    function getActiveStep() {
        if (activeTab === "account") {
            return 0;
        } else if (activeTab === "ownership") {
            return 1;
        } else if (activeTab === "identity") {
            return 2;
        } else if (activeTab === "payment") {
            return 3;
        }
    }

    return (
        <div className="Steps">
            <Stepper steps={steps} activeStep={getActiveStep()} completeColor={"#19ac71"} activeColor={"#19ac71"} />
        </div>
    );
}
    