import React from "react";

export default function InstructionsAdditionalInfo() {
    return (
        <>
        <div>
            <h3>Additional Information:</h3>
        </div>
        </>
    );
}