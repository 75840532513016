import React, {useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import { AppContext, useAppContext } from "../libs/contextLib";
import { onError } from "../libs/errorLib";
import FileUpload from "../components/FileUpload";
import InstructionsAdditionalInfo from "../components/InstructionsAdditionalInfo";
import { API } from "aws-amplify";
import "./AdditionalInfo.css";

export default function AdditionalInfo() {
    const [isProcessing, setIsProcessing] = useState(false);
    const [validated, setIsValidated] = useState(false);
    const [currentSection] = useState("additionalInfo");
    const [files, setFiles] = useState([]);
    const { application, setIsApplicationReloaded } = useAppContext();

    useEffect(() => {
        //Determine which documents are needed based on currentSection / classification list
        //load the files list and set the files list based on the currentSection type (i.e. files related to veteran, or disability)
        async function onLoad() {
            try {
                setIsProcessing(true);
                
                //Load files from application
                const applicationFilesList = await loadFiles();
                //filter files list based on current section
                if (applicationFilesList && applicationFilesList.length > 0) {
                    const filteredFilesList = applicationFilesList.filter(file => file.documentType === currentSection);
                    setFiles(filteredFilesList.map(file => {
                        var obj = {};
                        obj.name = file.S3FileName;
                        obj.documentId = file.documentId;
                        return obj;
                        
                    }));
                    if (filteredFilesList.length > 0) {
                        setIsValidated(true);
                    }
                }
                setIsProcessing(false);
            } catch (e) {
                onError(e);
            }
        }
        onLoad();
    }, [currentSection]);  // eslint-disable-line react-hooks/exhaustive-deps

    function loadFiles() {
        return API.get("applications", "/applications/" + application.applicationId + "/documents", {});
    }

    const handleSubmit = async (event) => {
        if (files && files.length > 0) {
            //Save application
            const params = {
                applicationStatus: "submitted",
                lastSubmissionDate: new Date().toISOString()
            };
            await saveApplication(params);
            //force application to reload
            setIsApplicationReloaded(false);
        }
    }

    function saveApplication(params) {
        return API.patch("applications", "/applications/" + application.applicationId, {
            body: params,
        });
    }

    return (
        <div className="content">
            { !isProcessing && currentSection && (
                <div className="AdditionalInfo">
                    <div className="instructions">
                        <InstructionsAdditionalInfo />
                    </div>
                    <div>
                        { !isProcessing && (
                            <AppContext.Provider
                                value={{ application, files, setFiles, setIsValidated }}
                            >
                                <FileUpload sectionName={currentSection} />
                            </AppContext.Provider>
                        )}
                    </div>
                    <div className="pageButtons">
                        <Button variant="primary" disabled={!validated} onClick={handleSubmit}>Submit</Button>
                    </div>
                </div>
            )}
        </div>
    );
}