import React from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import './CheckBox.scss';

const CheckBox = ({ checked, theme, className, onChange, children }) => {
  return (
    <div className="checkbox-input" onClick={() => onChange(!checked)}>
      <button
        type="button"
        className={classnames(
          'checkbox',
          checked && `checkbox--checked`,
          theme === 'dark' && `checkbox--${theme}`,
          className
        )}
        onClick={() => onChange(!checked)}
      >
        {checked &&
          <FontAwesomeIcon width="15px" height="15px" icon={faCheck} color={theme === 'dark' ? 'white' : '#19ac71'} />
        }
      </button>
      {children && <span className="checkbox-input__text">{children}</span>}
    </div>
  );
};

export default CheckBox;
