import React, {useEffect, useRef, useState} from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { useAppContext } from "../libs/contextLib";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { PLATFORM_LIST, PLATFORM_FORM_ID_PREFIX } from "../constants/socialPlatformConstants";

export default function SocialPlatform() {
    const { platforms, setPlatforms } = useAppContext();
    const [addNew, setAddNew] = useState(false);
    const platformNameEls = useRef([]);
    const platformValueEls = useRef([]);

    useEffect(() => {
        if (addNew && platformNameEls) {
            // focus on the last platform's name dropdown
            let lastRef = platformNameEls.current[platforms.length-1]
            lastRef.focus();
            setAddNew(false);
        }
    }, [addNew, platforms]);

    function renderPlatform(platform, index) {
        return (
            <div className="row-top-10" key={platform.id || index}>
                <Form.Row>
                    <Form.Group as={Col} md="3" controlId={platform.id} className="pad-right-10">
                        <Form.Control 
                            as="select" 
                            value={platform.name} 
                            size="lg" 
                            disabled={platform.isNameDisabled}
                            ref={(element) => platformNameEls.current[index] = element}
                            onChange={(e) => handleChange(e, "name", index)} >
                            <option>Select...</option>
                            { PLATFORM_LIST.map((socialMedia, index) => {
                                return (
                                    <option key={socialMedia.displayValue+index} value={socialMedia.name}>{socialMedia.displayValue}</option>
                                );
                            })}
                        </Form.Control>
                        <Form.Label>Platform</Form.Label>
                    </Form.Group>
                    <Form.Group as={Col} md="9" controlId={platform.id} className="pad-right-10">
                        <Form.Control 
                            type="text" 
                            size="sm"
                            maxLength="50"
                            onChange={(e) => handleChange(e, "value", index)}
                            value={platform.value === null ? '' : platform.value}
                            ref={(element) => platformValueEls.current[index] = element} />
                        <Form.Label>{platform.defaultInputLabel}</Form.Label>
                    </Form.Group>
                </Form.Row>
            </div>
        );
    }

    /**
     * Add new empty platform form entry
     */
    const handleAddPlatform = () => {
        let newPlatforms = [...platforms];
        newPlatforms.push({ id: PLATFORM_FORM_ID_PREFIX + platforms.length, name: "Select...", value: null, nameEditable: true})
        setPlatforms(newPlatforms);
        //window.requestAnimationFrame(setFocus);
        setAddNew(true);
    }

    function handleChange(event, property, index) {
        //const updatedPlatforms = platforms.map(x => (x.id === event.target.id ? { ...x, [property]: event.target.value} : x));
        const updatedPlatforms = platforms.map(x => {
            if (x.id === event.target.id) {
                let updatedPlatform = x;

                // update the property with latest change
                updatedPlatform[property] = event.target.value;
                var socialMediaLookup = PLATFORM_LIST.find(obj => { return obj.name === event.target.value })
                if (socialMediaLookup) { 
                    // set the input box label for given platform
                    updatedPlatform["defaultInputLabel"] = socialMediaLookup.defaultInputLabel;
                }   
                // if platform.value has no value yet, put a default value (i.e. @) into value box
                if (updatedPlatform["value"] === undefined || updatedPlatform["value"] === null || updatedPlatform["value"].trim() === '') {
                    // Find out what the default value is for the selected platform
                    //var socialMediaLookup = PLATFORM_LIST.find(obj => { return obj.name === event.target.value })
                    if (socialMediaLookup) {
                        updatedPlatform["value"] = socialMediaLookup.defaultInputValue;
                        updatedPlatform["defaultInputLabel"] = socialMediaLookup.defaultInputLabel;
                    }
                }

                return updatedPlatform
            }
            return x;
        });
        setPlatforms(updatedPlatforms);

        // if the change is on platform name, set focus on the value box
        if (property === 'name') {
            let valueEl = platformValueEls.current[index]
            // focus on the value input box of this platform
            valueEl.focus();
        }
    }

    return (
        <div className="website">
            <div>
                { /* Addtional Platforms, if available */ 
                    platforms && platforms.length > 0 && 
                    platforms.map((platform, index) => {
                        return renderPlatform(platform, index);
                    })
                }
            </div>
            <div> 
                <Form.Row >
                    <Button className="sideAddBtn" onClick={handleAddPlatform}>
                        <FontAwesomeIcon icon={faPlusCircle}></FontAwesomeIcon> 
                    </Button>
                    <div className="textLink" onClick={handleAddPlatform}>Add</div>
                </Form.Row>
            </div>
        </div>
    );
}