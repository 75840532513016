import React, {useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { onError } from "../libs/errorLib";
import { useHistory } from "react-router-dom";
import { AppContext, useAppContext } from "../libs/contextLib";
import { API } from "aws-amplify";
import "./Websites.css";
import { useLoader } from "../context/LoaderContext";
import Website from "../components/Website";
import SocialPlatform from "../components/SocialPlatform";
import * as Constants from "../constants/socialPlatformConstants";

export default function Websites() {
    const [isProcessing, setIsProcessing] = useState(true);
    const [, setLoading] = useLoader();
    const { application, setIsApplicationReloaded } = useAppContext();
    const [websites, setWebsites] = useState([]);
    const [platforms, setPlatforms] = useState([]);
    const history = useHistory();

    function initialWebsites(websites) {
        if (websites === null || websites === undefined || websites.length < 1) {
            setWebsites([
                { id: Constants.WEBSITE_FORM_ID_PREFIX+0, value: Constants.WEBSITE_VALUE_PREFIX},
                { id: Constants.WEBSITE_FORM_ID_PREFIX+1, value: Constants.WEBSITE_VALUE_PREFIX},
            ]);
        } else {
            let newWebsites = websites.map((website, index) => {
                if (!website.id) {
                    website.id = Constants.WEBSITE_FORM_ID_PREFIX + index;
                }
                return website;
            })
            // UX: Append a new line to assist with data entry
            newWebsites.push({ id: Constants.WEBSITE_FORM_ID_PREFIX + websites.length, value: Constants.WEBSITE_VALUE_PREFIX})
            setWebsites(newWebsites);
        }
    }

    function initialPlatforms(platforms) {
        if (platforms === null || platforms === undefined || platforms.length < 1) {
            setPlatforms([
                { id: Constants.PLATFORM_FORM_ID_PREFIX+0, name: "Instagram", value: Constants.PLATFORM_VALUE_PREFIX, defaultInputLabel: Constants.HANDLE_FORM_LABEL, isNameDisabled: true},
                { id: Constants.PLATFORM_FORM_ID_PREFIX+1, name: "Facebook", value: Constants.PLATFORM_FACEBOOK_VALUE_PREFIX, defaultInputLabel: Constants.WEBSITE_FORM_LABEL, isNameDisabled: true},
                { id: Constants.PLATFORM_FORM_ID_PREFIX+2, name: "TikTok", value: Constants.PLATFORM_VALUE_PREFIX, defaultInputLabel: Constants.HANDLE_FORM_LABEL, isNameDisabled: true},
                { id: Constants.PLATFORM_FORM_ID_PREFIX+3, name: "Pinterest", value: Constants.PLATFORM_VALUE_PREFIX, defaultInputLabel: Constants.HANDLE_FORM_LABEL, isNameDisabled: true},
                { id: Constants.PLATFORM_FORM_ID_PREFIX+4, name: "YouTube", value: Constants.PLATFORM_VALUE_PREFIX, defaultInputLabel: Constants.HANDLE_FORM_LABEL, isNameDisabled: true},
                { id: Constants.PLATFORM_FORM_ID_PREFIX+5, name: "Select...", value: Constants.PLATFORM_VALUE_PREFIX, defaultInputLabel: Constants.HANDLE_FORM_LABEL, isNameDisabled: false},
            ]);
        } else {
            let newPlatforms = platforms.map((platform, index) => {
                // since on save, we don't save the form id (which is only used to handle focus/control on ux)
                // we need to add the id based on array length 
                // also allow the platform name to be editable

                var socialMediaLookup = Constants.PLATFORM_LIST.find(obj => { return obj.name === platform.name })
                if (socialMediaLookup) {
                    platform["defaultInputLabel"] = socialMediaLookup.defaultInputLabel;
                } else {
                    platform["defaultInputLabel"] = Constants.WEBSITE_FORM_LABEL;
                }
                    
                if (!platform.id) {
                    platform.id = Constants.PLATFORM_FORM_ID_PREFIX + index;
                    platform.isNameDisabled = false;
                }
                return platform;
            })

            // UX: Append a new line to assist with data entry
            newPlatforms.push({ id: Constants.PLATFORM_FORM_ID_PREFIX + platforms.length, name: "Select...", value: null, isNameDisabled: false})
            setPlatforms(newPlatforms);
        }
    }

    useEffect(() => {
        //ensures window scrolls to top when loading this page
        window.scrollTo(0, 0);
        
        //Determine which classifications user is applying for
        async function onLoad() {
            try {
                setIsProcessing(true);
                initialWebsites(application.websites);
                initialPlatforms(application.platforms);
                setIsProcessing(false);
            } catch (e) {
                onError(e);
            }
        }
        onLoad();
    }, []);  // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(() => {
        setLoading(isProcessing);

        // on initial load of page, set 
    }, [isProcessing, setLoading]);

    const handleSubmit = async (event, destination) => {
        event.preventDefault();
        event.stopPropagation();

        // Remove any Websites that are empty (i.e. "" or "https://")
        const newWebsites = websites.filter(function (website) {
            // a bit of a hack, but we don't need the form id saved, since that's only for react UX rendering/focusing  
            delete website.id
            return (website.value !== undefined 
                && website.value !== null 
                && (website.value.trim() !== "https://" && website.value.trim() !== "")
            );
        });

        // Remove any Platforms that are empty (i.e. both Platform name and value are missing)
        const newPlatforms = platforms.filter(function (platform) {
            // a bit of a hack, but we don't need the form id saved, since that's only for react UX rendering/focusing 
            // we also don't need the "nameEditable" property since that's also for UX for initial landing into the page
            delete platform.id
            delete platform.isNameDisabled
            delete platform.defaultInputLabel
            return (
                platform.name !== undefined && platform.name !== null 
                && platform.value !== undefined && platform.value !== null 
                && (platform.name.trim() !== "Select...")
                && (platform.value.trim() !== "@" && platform.value.trim() !== "")
            );
        });

        // Save data into application
        const params = {
            websites: newWebsites,
            platforms: newPlatforms
        };
        //1. save classifications
        await saveApplication(params);
        //force application to reload
        setIsApplicationReloaded(false);

        //go to destination
        if (destination === "back") {
            history.push("classifications");
        } else {
            history.push("ownership");
        }
    }


    function saveApplication(params) {
        return API.patch("applications", "/applications/" + application.applicationId, {
            body: params,
        });
    }

    return (
        <div className="content">
            { !isProcessing && (
                <Form noValidate onSubmit={handleSubmit}>
                    <h3>Enter all websites & social handles to opt into relevant campaigns.</h3>
                    <div className="websites">
                        <div className="row-bottom-30" />
                        <h3>Business Website(s) - list all applicable</h3>
                        <div className="left-text"> 
                            <div>
                                <AppContext.Provider
                                    value={{ websites, setWebsites }}
                                >
                                    <Website />
                                </AppContext.Provider>
                            </div>
                        </div>
                        <div className="row-bottom-40"></div>
                        <h3>Social Media - list all applicable</h3>
                        <div className="row-bottom-20" />
                        <div className="left-text"> 
                            <div>
                                <AppContext.Provider
                                    value={{ platforms, setPlatforms }}
                                >
                                    <SocialPlatform />
                                </AppContext.Provider>
                            </div>
                        </div>
                            
                        <div className="contentBody">
                            <div className="pageButtons-multiple">
                                <Button variant="secondary" onClick={event => handleSubmit(event, 'back')} disabled={isProcessing}>BACK</Button>
                                <Button variant="primary" onClick={event => handleSubmit(event, 'next')}  disabled={isProcessing} >NEXT</Button>
                            </div>
                        </div>
                    </div>
                </Form>
            )}
        </div>
    );
}