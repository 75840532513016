import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAppContext } from "../libs/contextLib";
// import Col from "react-bootstrap/Col";
// import Row from "react-bootstrap/Row";
// import {CopyToClipboard} from 'react-copy-to-clipboard';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Success.css";

export default function Success() {
    const { isAuthenticated, application } = useAppContext();
    const [isLoading, setIsLoading] = useState(true);
    // const [copied, setCopied] = useState(false);
    const history = useHistory();

    // const shareUrl = 'https://www.sertify.io/small-businesses';

    useEffect(() => {
        async function onLoad() {
            if (!isAuthenticated || !application) {
                history.push("/");
            } else if (application) {
                if (application.applicationStatus !== 'submitted' 
                    && application.applicationStatus !== 'approved'
                    &&  application.applicationStatus !== 'denied') {
                        history.push("/classifications");
                }
            }
            setIsLoading(false);
        }

        onLoad();
    }, [isAuthenticated]); // eslint-disable-line react-hooks/exhaustive-deps

    /* 
    function getMailToContent() {
        var content = "mailto:?subject=Check out Sertify!&body=Hi,%0D%0A%0D%0AI just applied to Sertify to help bring new customers to my small business.%0D%0A%0D%0AJust thought you might like to check it out - https://www.sertify.io/small-businesses%0D%0A%0D%0A" + application.firstName;
        return content;
    }

    function onCopy() {
        setCopied(true);
    };

    const shareFB = (e) => {
        e.preventDefault();
        e.stopPropagation();
        window.FB.ui({
            method: 'share',
            href: shareUrl,
        }, function(response){});
    };
    */

    return ( 
        !isLoading && (
            <div className="content">
                <div className="success">
                    <div className="contentHeader row-bottom-20">
                        <h2>Thank You!</h2>
                    </div>
                    <div className="row-bottom-20"> 
                        We are reviewing your documents and will get back to you over the next few business days. 
                    </div>
                    <div className="row-bottom-30">
                        { application.subscriptionType === 'basic' && (
                            <>
                            After you Sertify, we will add your business to our directory listing!  Interested in upgrading?  <a href="mailto:support@sertify.io">Contact us</a>! 
                            </>
                        )}
                        { (application.subscriptionType === 'premium' || application.subscriptionType === 'premiumPartner') && (
                            <>
                            After you Sertify, you can get window decals, add Sertify Stamps to your digital platforms, and more. We can't wait to help grow your business.
                            </>
                        )}
                    </div>
                </div>
            </div>
        )
    );
}