import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import LoaderButton from "../components/LoaderButton";
import { useAppContext } from "../libs/contextLib";
import { useFormFields } from "../libs/hooksLib";
import "./Login.css";

export default function Login() {
  const { userHasAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: ""
  });

  function validateForm() {
    return fields.email.length > 0 && fields.password.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await Auth.signIn(fields.email, fields.password);
      userHasAuthenticated(true);
    } catch (e) {
      setError("Sorry, we can't find that username and password. Please reset your password or try again.");
      setIsLoading(false);
    }
  }

  return (
    <div className="login">
      { error && (
        <div className="error row-bottom-10">
          {error}
        </div>
      )}
      <h2>Welcome Back!</h2>
        <h3>Please log in to finish your application.</h3>
        <form onSubmit={handleSubmit}>
        <Form.Row>
          <Form.Group as={Col} controlId="email" size="large">
            <Form.Control
              autoFocus
              type="email"
              value={fields.email}
              onChange={handleFieldChange}
            />
            <Form.Label>Email</Form.Label>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="password" size="large">
            <Form.Control
              type="password"
              value={fields.password}
              onChange={handleFieldChange}
            />
            <Form.Label>Password</Form.Label>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <div className="pageButtons-single">
            <LoaderButton
              block
              type="submit"
              variant="primary"
              isLoading={isLoading}
              disabled={!validateForm()}
            >
              LOG IN
            </LoaderButton>
          </div>
        </Form.Row>
        <Link to="/login/reset">Forgot password?</Link>
        <Link to="/">New to Sertify?  Apply now</Link>
      </form>
    </div>
  );
}