import React, { createContext, useState, useContext } from 'react';

const LoaderContext = createContext([
  false,
  (value) => {}
]);

export const LoaderProvider = ({ children }) => {
  const loadingState = useState(false);

  return (
    <LoaderContext.Provider value={loadingState}>
      {children}
    </LoaderContext.Provider>
  );
};

export const useLoader = () => {
  const loaderState = useContext(LoaderContext);
  return loaderState;
};

export const withLoader = (Comp) => () => (
  <LoaderProvider>
    <Comp />
  </LoaderProvider>
);

export default LoaderContext;
