import React, {useEffect, useState} from "react";
import { useHistory } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { AppContext, useAppContext } from "../libs/contextLib";
import { getPartnerSettings } from "../libs/utilsLib"
import { onError } from "../libs/errorLib";
import FileUpload from "../components/FileUpload";
import InstructionsOwnership from "../components/InstructionsOwnership";
import { API } from "aws-amplify";
import "./Ownership.css";

export default function Ownership() {
    const [isProcessing, setIsProcessing] = useState(false);
    const [validated, setIsValidated] = useState(false);
    const [currentSection] = useState("ownership");
    const [files, setFiles] = useState([]);
    const [partnerSettings, setPartnerSettings] = useState(null);
    const [businessLabel, setBusinessLabel] = useState("Business");
    const { isAuthenticated, application } = useAppContext();
    const history = useHistory();

    useEffect(() => {
        //ensures window scrolls to top when loading this page
        window.scrollTo(0, 0);

        function loadFiles() {
            return API.get("applications", "/applications/" + application.applicationId + "/documents", {});
        }

        //Determine which documents are needed based on currentSection / classification list
        //load the files list and set the files list based on the currentSection type (i.e. files related to veteran, or disability)
        async function onLoad() {
            try {
                setIsProcessing(true);
                
                //Load files from application
                const applicationFilesList = await loadFiles();
                //filter files list based on current section
                if (applicationFilesList && applicationFilesList.length > 0) {
                    const filteredFilesList = applicationFilesList.filter(file => file.documentType === currentSection);
                    setFiles(filteredFilesList.map(file => {
                        var obj = {};
                        obj.fileName = file.S3FileName;
                        obj.documentId = file.documentId;
                        return obj;
                        
                    }));
                    if (filteredFilesList.length > 0) {
                        setIsValidated(true);
                    }
                }

                if (application.partnerCode) {
                    // Retrieve partner settings
                    const partnerSettings = getPartnerSettings(application.partnerCode);
                    if (partnerSettings) {
                        setPartnerSettings(partnerSettings);
                    }
                }

                setIsProcessing(false);
            } catch (e) {
                onError(e);
            }
        }
        onLoad();
    }, [isAuthenticated, currentSection]);  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (partnerSettings && partnerSettings.businessLabel) {
            setBusinessLabel(partnerSettings.businessLabel);
        }
    }, [partnerSettings]);  

    const handleSubmit = async (event, destination) => {
        event.preventDefault();
        event.stopPropagation();
        
        //go to destination
        if (destination === "back") {
            history.push("websites");
        } else {
            history.push("identity");
        }
    }

    return (
        <div className="content">
            { !isProcessing && currentSection && (
                <div>
                    <div className="contentBody">
                        <InstructionsOwnership partnerSettings={partnerSettings} businessLabel={businessLabel}/>
                    </div>
                    <div className="contentBody">
                        { !isProcessing && (
                            <AppContext.Provider
                                value={{ application, files, setFiles, setIsValidated }}
                            >
                                <FileUpload sectionName={currentSection} />
                            </AppContext.Provider>
                        )}
                    </div>
                    <div className="contentBody">
                        <div className="pageButtons-multiple">
                            <Button variant="secondary" onClick={event => handleSubmit(event, 'back')} disabled={isProcessing}>BACK</Button>
                            <Button variant="primary" onClick={event => handleSubmit(event, 'next')}  disabled={!validated} >NEXT</Button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}