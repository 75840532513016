import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Form from 'react-bootstrap/Form';
import LoaderButton from "../components/LoaderButton";
import { useFormFields } from "../libs/hooksLib";
import "./Login.css";

export default function ResetPassword() {
  const [fields, handleFieldChange] = useFormFields({
    code: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [codeSent, setCodeSent] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [isSendingCode, setIsSendingCode] = useState(false);
  const [error, setError] = useState("");

  function validateCodeForm() {
    return fields.email.length > 0;
  }

  function validateResetForm() {
    return (
      fields.code.length > 0 &&
      fields.password.length > 0 &&
      fields.password === fields.confirmPassword
    );
  }

  async function handleSendCodeClick(event) {
    event.preventDefault();

    setIsSendingCode(true);

    try {
      await Auth.forgotPassword(fields.email);
      setCodeSent(true);
      setError('');
    } catch (error) {
      setError("Sorry, we can't find that username and password.");
      setIsSendingCode(false);
    }
  }

  async function handleConfirmClick(event) {
    event.preventDefault();

    setIsConfirming(true);

    try {
      await Auth.forgotPasswordSubmit(
        fields.email,
        fields.code,
        fields.password
      );
      setConfirmed(true);
    } catch (error) {
      if (error.code === 'InvalidParameterException') {
        setError("Password must be 8-20 characters long, contain a lowercase letter, uppercase letter, and number.");
      } else if (error.code === 'CodeMismatchException') {
        setError("Invalid confirmation code.  Please check your email for the verification code.");
      } else if (error.code === 'LimitExceededException' ) {
        setError("Attempt limit exceeded, please try after some time.");
      }
      setIsConfirming(false);
    }
  }

  function renderRequestCodeForm() {
    return (
      <div className="send">
        { error && (
          <div className="error row-bottom-10">
            {error}
          </div>
        )}
        { error && (
          <div className="error row-bottom-10">
            <Link to="/">New to Sertify?  Apply now</Link>
          </div>
        )}
        <h3>Please enter your email address below to receive a verification code.</h3>
        <form onSubmit={handleSendCodeClick}>
          <Form.Row>
            <Form.Group as={Col} size="large" controlId="email">
              <Form.Control
                autoFocus
                type="email"
                maxLength="50"
                value={fields.email}
                onChange={handleFieldChange}
              />
              <Form.Label>Email</Form.Label>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <div className="pageButtons-single">
              <LoaderButton
                block
                type="submit"
                size="large"
                isLoading={isSendingCode}
                disabled={!validateCodeForm()}
              >
                SEND VERIFICATION
              </LoaderButton>
            </div>
          </Form.Row>
          <Link to="/login">Return to Login</Link>
        </form>
      </div>
    );
  }

  function renderConfirmationForm() {
    return (
      <div>
        { error && (
          <div className="error row-bottom-10">
            {error}
          </div>
        )}
        <h3>Please check your email ({fields.email}) for the confirmation code.</h3>
        <form onSubmit={handleConfirmClick}>
          <Form.Row>
            <Form.Group as={Col} size="large" controlId="code">
              <Form.Control
                autoFocus
                type="tel"
                maxLength="20"
                value={fields.code}
                onChange={handleFieldChange}
              />
              <Form.Label>Confirmation Code</Form.Label>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group  as={Col} size="large" controlId="password">
              <Form.Control
                type="password"
                maxLength="20"
                value={fields.password}
                onChange={handleFieldChange}
              />
              <Form.Label>New Password</Form.Label>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group  as={Col} size="large" controlId="confirmPassword">
              <Form.Control
                type="password"
                maxLength="20"
                value={fields.confirmPassword}
                onChange={handleFieldChange}
              />
              <Form.Label>Confirm Password</Form.Label>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <div className="pageButtons-single">
              <LoaderButton
                block
                type="submit"
                size="large"
                isLoading={isConfirming}
                disabled={!validateResetForm()}
              >
                CONFIRM
              </LoaderButton>
            </div>
          </Form.Row>
        </form>
      </div>
    );
  }

  function renderSuccessMessage() {
    return (
      <div className="success">
        <h3>Your password has been reset.</h3>
        <p>
          <Link to="/login">
            Click here to login with your new credentials.
          </Link>
        </p>
      </div>
    );
  }

  return (
    <div className="login">
      {!codeSent
        ? renderRequestCodeForm()
        : !confirmed
        ? renderConfirmationForm()
        : renderSuccessMessage()}
    </div>
  );
}