import React from "react";
import Form from "react-bootstrap/Form";
import { useAppContext } from "../libs/contextLib";
import states from "../states.json"

export default function USStates(props) {
    const { handleFieldChange } = useAppContext();

    return (
        <Form.Control required={props.isRequired} as="select" size="lg" onChange={handleFieldChange} >
            <option></option>
            { states.map((state, index) => {
                return (
                    <option key={state.name+"-"+index} value={state.abbreviation}>{state.name}</option>
                );
            })}
        </Form.Control>
    );
}