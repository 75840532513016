const dev = {
    STRIPE_KEY: "pk_test_51Gwq5JJkIccgYbydaBfyzz5Ez6RZTFEjFIBGy3DTMjYYDUPjbVl9XCq3BfaLK3JZGvL9WrBBTjXOt10lQNxIvfQI00Ln6UyAft",
    s3: {
        REGION: "us-east-1",
        BUCKET: "sertify-apply-api-dev-attachmentsbucket-dn5utwmj1jgk"
    },
    apiGatewayApply: {
        REGION: "us-east-1",
        URL: "https://v4ebc9u20b.execute-api.us-east-1.amazonaws.com/dev"
    },
    apiGatewayStripe: {
        REGION: "us-east-1",
        URL: "https://trfvtb065a.execute-api.us-east-1.amazonaws.com/dev"
    },
    cognito: {
        REGION: "us-east-1",
        USER_POOL_ID: "us-east-1_8Db48Cs2D",
        APP_CLIENT_ID: "5gulv2ih5rem6qnf4jnvmamb2l",
        IDENTITY_POOL_ID: "us-east-1:ba3f0eff-e621-4ac2-8ba1-2bfccef989e2"
    },
};
  
/** TODO: Need to Update to Prod */
const prod = {
    STRIPE_KEY: "pk_live_51Gwq5JJkIccgYbydARpi2cWBCsZuEPFuO3UZU7o92r0dTSHWnX6pJ6UGkFhFDmdhgt7ZV0FnWQLEpkReMuNaFiBr00ys1ih1Fx",
    s3: {
        REGION: "us-east-1",
        BUCKET: "sertify-apply-api-prod-attachmentsbucket-1pgu0z08ml2ii"
    },
    apiGatewayApply: {
        REGION: "us-east-1",
        URL: "https://8nh7jpwx4i.execute-api.us-east-1.amazonaws.com/prod"
    },
    apiGatewayStripe: {
        REGION: "us-east-1",
        URL: "https://d5t4zorz52.execute-api.us-east-1.amazonaws.com/prod"
    },
    cognito: {
        REGION: "us-east-1",
        USER_POOL_ID: "us-east-1_3Ww2qjRjz",
        APP_CLIENT_ID: "e4hhbovogitud528pnbljsljs",
        IDENTITY_POOL_ID: "us-east-1:bdf06d9b-a556-4d00-afa1-5d69c69b478b"
    },
};
  
// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};