import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { useAppContext } from "../libs/contextLib";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { WEBSITE_VALUE_PREFIX, WEBSITE_FORM_ID_PREFIX, WEBSITE_FORM_LABEL} from "../constants/socialPlatformConstants";
import "./Website.css";

export default function Website() {
    const { websites, setWebsites } = useAppContext();
    const [addNew, setAddNew] = useState(false);
    const lastRef = useRef(null);
    
    useEffect(() => {
        if (addNew && lastRef.current) {
            lastRef.current.focus();
        }
    }, [addNew, websites]);

    function renderWebsite(website, index) {
        return (
            <div className="row-top-10" key={website.id || index}>
                <Form.Row>
                    <Form.Group as={Col} controlId={website.id}>
                        <Form.Control 
                            type="text" 
                            required
                            size="sm" 
                            maxLength="500"
                            inputMode="url"
                            autoFocus={index === 0 ? true: false}
                            value={website.value}
                            ref={index === websites.length - 1 ? lastRef : undefined} 
                            onChange={(e) => handleChange(e)}/>
                        <Form.Label>{WEBSITE_FORM_LABEL}</Form.Label>
                        <Form.Control.Feedback type="invalid">Please enter your website</Form.Control.Feedback>
                    </Form.Group>
                </Form.Row>
            </div>
        );
    }

    /**
     * Add new empty website form entry
     */
    const handleAddWebsite = () => {
        let newWebsites = [...websites];
        newWebsites.push({ id: WEBSITE_FORM_ID_PREFIX + websites.length, value: WEBSITE_VALUE_PREFIX})
        setWebsites(newWebsites);
        setAddNew(true);
    }

    function handleChange(event) {
        const updatedWebsites = websites.map(x => (x.id === event.target.id ? { ...x, value: event.target.value } : x));
        setWebsites(updatedWebsites);
    }

    return (
        <div className="website">
            <div>
                { /* Addtional Websites, if available */ 
                    websites && websites.length > 0 && 
                    websites.map((website, index) => {
                        return renderWebsite(website, index);
                    })
                }
            </div>
            <div> 
                <Form.Row>
                    <Button className="sideAddBtn" onClick={handleAddWebsite}>
                        <FontAwesomeIcon icon={faPlusCircle}></FontAwesomeIcon> 
                    </Button>
                    <div className="textLink" onClick={handleAddWebsite}>Add</div>
                </Form.Row>
            </div>
        </div>
    );
}