import React from "react";
import InfoIcon from './InfoIcon';

export default function InstructionsOwnership({partnerSettings, businessLabel}) {
    function getInfoIconText() {
        return (
            <div>
                <p>We need this information to verify that you own the business.</p>
                <p>We respect your privacy and will not use your information for any other purpose.</p>
                <p>We only need to see the portion of the document that shows both your name and the {businessLabel.toLowerCase()} name.</p>
            </div>
        );
    }

    function getOwnershipInfoText() {
        if (partnerSettings) {
            return partnerSettings.ownerInfoText;
        }
    }

    return (
        <>
            <div>
                <h3>Confirm Ownership</h3>
            </div>
            <div>
                {getOwnershipInfoText()} We need a current document that shows both the name of the <strong>{businessLabel}</strong> and the name of the <strong>Owner</strong>. Examples: 
                <InfoIcon 
                    keyName={"ownership"}
                    message={getInfoIconText()}
                />
            </div>
            <div>
                <ul>
                    <li>Business license</li>
                    <li>Local permit</li>
                    <li>Insurance document</li>
                    <li>Lease agreement</li>
                    <li>Financial or tax document (e.g. Form 1099-NEC)</li>
                    <li>Legal document (e.g. LLC Formation)</li>
                </ul>
            </div>
        </>
    );
}